
















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageAllocation",
})
export default class PageAllocation extends Vue {}
