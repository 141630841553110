





































































































































































































































































































































































































































































































































































































































































import * as util from "util";
import { Component, Mixins } from "vue-property-decorator";

import ContractCfg from "@/logic/contract-config";
import Contract from "@/logic/block_chain/abstract/contract";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Method from "@/logic/block_chain/abstract/method";
import { Api } from "@/logic/api";
import WalletMixin from "@/mixins/wallet";
import { DtoActivityInfo, SellActivityType } from "@/logic/api/dto/sell.dto";
import {
  ExchangeAbi,
  ERC20Abi,
  SellAbi,
  ERC721PermitAbi,
} from "@/logic/contract-config/abi";
import BigNumber from "bignumber.js";
import PagePaging from "../components/page/page-paging.vue";

interface SellActivityData {
  inWhiteList: boolean;
  buy: boolean;
  price: BigNumber;
  approve: BigNumber;
}

interface ExchangeActivityData {
  nftList: BigNumber[];
}

interface ActivityInfo {
  info: DtoActivityInfo;
  data: SellActivityData | ExchangeActivityData;
}

interface HeroInfo {
  nftId: BigNumber;
  cfg: unknown;
}

interface NftChooseItem {
  nftId: BigNumber;
  image: string;
  approve: boolean;
}

const signMessage =
  'This is KingdomX, welcome!\n\
\n\
Click "Sign" to sign this message. Only the signature will be used to verify the whitelist and will not be saved at any place.\n\
This request will not trigger a blockchain transaction or cost any gas fees.\n\
\n\
The signature will expire after 120 seconds.\n\
\n\
UUID: %s';

@Component({
  components: {
    PagePaging,
  },
})
export default class CampaignHero extends Mixins(WalletMixin) {
  SellActivityType = SellActivityType;
  RewardCinfig = ContractCfg.PvpReward;
  isShowNotRewardRemindPanel = false; // 是否显示没在白名单无法领取提示弹框
  isShowBoughtRemindPanel = false; // 是否显示已购买提示弹框
  isShowPurchasedBox = false; // 是否显示右侧已购买提示条
  isShowDragonBox = true;
  isShowMythicBox = false;
  offFrontDragonPrice = "--";
  offFrontMythicPrice = "--";
  DragonBoxPrice = "--";
  MythicBoxPrice = "--";
  nowShowInfo = "KT";

  // KT记录当前页数
  KTPage = 1;
  KTPage_m = 1;
  // Box记录当前页数
  BoxPage = 1;
  BoxPage_m = 1;
  // KT记录总数
  KTinfoNum = 0;
  // Box记录总数
  BoxinfoNum = 0;
  // pc端分页数量
  pcPagingSize = 4;
  // 移动端分页数量
  MobilePagingSize = 5;
  // 待领取KT的数量
  unclaimKTNum = 0;
  // 待领取Box的数量
  unclaimBoxNum = 0;
  // 领取加载等待界面
  isShowProcessing = false;
  // 领取结果信息
  calimResultInfo = "";

  // wallet
  heroContractR!: Contract<MethodReadonly>;
  heroContract!: Contract<Method>;
  RewardCinfigContractR!: Contract<Method>;

  // business
  activityReady = false;
  activityWaitQueue = [] as (() => void)[];
  activityList: ActivityInfo[] = [];
  heroList: HeroInfo[] = [];
  now = 0;

  // choose hero
  showNftChoose = false;
  currentChooseIndex = 0;
  chooseNftList = [] as NftChooseItem[];

  // KT 分页切割后数据
  KtHisList: any[] = [];

  // 移动端 KT 分页切割后数据
  KtHisList_m: any[] = [];

  //三种状态 0：不能领取，1：待领取，2：已领取
  KtDataList: any[] = [];

  // Box 分页切割后数据
  BoxHisList: any[] = [];

  // 移动端 Box 分页切割后数据
  BoxHisList_m: any[] = [];

  //三种状态 0：不能领取，1：待领取，2：已领取
  BoxDataList: any[] = [];

  isBuying = false; // 是否正在处理中
  TipText = {
    RewardKTSuccess: "Claimed KT successfully, please check in your wallet.",
    RewardBoxSuccess: "Claimed Box successfully, please check in your wallet.",
    RewardFailed: "Claim failed, please refresh the page to try again.",
  };

  // KT切换页面逻辑
  public changepage(nowPage: number): void {
    this.KTPage = nowPage;
    this.KtHisList = this.KtDataList.slice(
      (nowPage - 1) * this.pcPagingSize,
      nowPage * this.pcPagingSize
    );
  }

  public changepage_m(nowPage: number): void {
    this.KTPage_m = nowPage;
    this.KtHisList_m = this.KtDataList.slice(
      (nowPage - 1) * this.MobilePagingSize,
      nowPage * this.MobilePagingSize
    );
  }

  // 盲盒切换页面逻辑
  public changeMysterypage(nowPage: number): void {
    this.BoxPage = nowPage;
    this.BoxHisList = this.BoxDataList.slice(
      (nowPage - 1) * this.pcPagingSize,
      nowPage * this.pcPagingSize
    );
  }

  public changeMysterypage_m(nowPage: number): void {
    this.BoxPage_m = nowPage;
    this.BoxHisList_m = this.BoxDataList.slice(
      (nowPage - 1) * this.MobilePagingSize,
      nowPage * this.MobilePagingSize
    );
  }

  // 关闭领取结果面板
  public closeBoard(type: any): void {
    if (type == "result") {
      this.calimResultInfo = "";
    }

    this.getRewardInfo();
  }

  // 创建合约
  public created(): void {
    // 创建只读合约对象，用于获取链上信息
    this.heroContractR = this.wallet.ReadonlyBlockChain.createContract(
      ContractCfg.Hero.Contract.address,
      ContractCfg.Hero.Contract.abis
    );

    // 添加账号变更的会带哦
    this.wallet.addAccountChangeHandler(this.onAccountChg.bind(this));
    this.onInit();
  }

  // 获取待领取信息
  public async getRewardInfo(): Promise<void> {
    // 获取领取kt数量和信息逻辑
    this.KtDataList = [];
    var info = await Api.getRewardInfoKT();
    this.KTinfoNum = info.rewards.length;
    // this.KtDataList = info.rewards;
    info.rewards.forEach((info: any) => {
      if (info.claimedState == "0") {
        this.KtDataList.push(info);
      }
    });
    info.rewards.forEach((info: any) => {
      if (info.claimedState == "1") {
        this.KtDataList.push(info);
      }
    });

    if (this.KTinfoNum <= 4) {
      this.KtHisList = this.KtDataList;
    } else {
      this.changepage(1);
    }
    if (this.KTinfoNum <= 5) {
      this.KtHisList_m = this.KtDataList;
    } else {
      this.changepage_m(1);
    }

    this.unclaimKTNum = 0;
    this.KtDataList.forEach((item) => {
      if (item.claimedState == "0") {
        this.unclaimKTNum += parseInt(item.rewardCount.slice(0, -18));
      }
    });

    // 获取领取box数量和信息逻辑
    this.BoxDataList = [];
    var boxinfo = await Api.getRewardInfoBox();
    this.BoxinfoNum = boxinfo.rewards.length;
    // this.BoxDataList = boxinfo.rewards;

    boxinfo.rewards.forEach((info: any) => {
      if (info.claimedState == "0") {
        this.BoxDataList.push(info);
      }
    });
    boxinfo.rewards.forEach((info: any) => {
      if (info.claimedState == "1") {
        this.BoxDataList.push(info);
      }
    });
    if (this.BoxinfoNum <= 4) {
      this.BoxHisList = this.BoxDataList;
    }
    if (this.BoxinfoNum <= 5) {
      this.BoxHisList_m = this.BoxDataList;
    }
    this.changeMysterypage(1);
    this.changeMysterypage_m(1);
    this.unclaimBoxNum = 0;
    this.BoxDataList.forEach((item) => {
      if (item.claimedState == "0") {
        this.unclaimBoxNum += 1;
      }
    });
  }

  // 执行批量领取Kt逻辑
  public async doKTClaim(): Promise<void> {
    this.isShowProcessing = true;
    try {
      var rewardIdList: number[] = [];
      var signatureList: string[] = [];
      var ktAmountList: number[] = [];
      var mintMysteryBoxList: any[] = [];
      for (var i = 0; i < this.KtDataList.length; i++) {
        if (this.KtDataList[i].claimedState == 0) {
          let itemInfo = await Api.PvPclaimReward(
            parseInt(this.KtDataList[i].rewardId)
          );
          if (itemInfo.signature) {
            rewardIdList.push(itemInfo.reward.rewardId);
            signatureList.push(itemInfo.signature);
            ktAmountList.push(itemInfo.reward.rewardCount);
            mintMysteryBoxList.push(false);
          }
        }
      }
      let getContractReward: any = await this.RewardCinfigContractR.methods
        .getGameRewardList(
          this.currentAddress,
          rewardIdList,
          signatureList,
          ktAmountList,
          mintMysteryBoxList
        )
        .send();
      if (getContractReward) {
        this.calimResultInfo = this.TipText.RewardKTSuccess;
      } else {
        this.calimResultInfo = this.TipText.RewardFailed;
      }
    } catch (error) {
      this.calimResultInfo = this.TipText.RewardFailed;
    }

    this.isShowProcessing = false;

    // this.getRewardInfo();
  }

  // 执行领取宝箱的逻辑
  public async doBoxClaim(item: any): Promise<void> {
    this.isShowProcessing = true;

    try {
      var caliminfo = await Api.PvPclaimReward(parseInt(item.rewardId));
      let getContractReward: any = await this.RewardCinfigContractR.methods
        .getGameReward(
          this.currentAddress,
          parseInt(item.rewardId),
          caliminfo.signature,
          0,
          true
        )
        .send();
      if (getContractReward) {
        this.calimResultInfo = this.TipText.RewardBoxSuccess;
      } else {
        this.calimResultInfo = this.TipText.RewardFailed;
      }
    } catch (error) {
      this.calimResultInfo = this.TipText.RewardFailed;
    }

    // this.calimResultInfo = caliminfo.message;
    this.isShowProcessing = false;
    // this.getRewardInfo();
  }

  // 用户页面加载的初始化
  public async onInit(): Promise<void> {
    // 定时器刷新now
    setInterval(() => {
      this.now = Math.trunc(Date.now() / 1000);
    }, 1000);
    // 从中心化服务器，请求所有活动列表，但活动信息缺少与用户相关的信息
    const result = await Api.getSellActivityList();
    this.activityList = result.list
      .map((v) => ({
        info: v,
        data: this.getEmptyActivityData(v.type),
      }))
      // 过滤出售卖的活动 type = 0，售卖； type = 1，领取；
      .filter((item) => {
        return item.info.type === 0;
      });
    this.onAcitivtyReady();
  }

  // 用户登录获取cookie
  public async tologin(): Promise<void> {
    const currentaddress = this.currentAddress;
    const signMessage = await Api.getSignMessage();
    let signature = await this.wallet.signPersonalMessage(
      signMessage.signMessage,
      currentaddress
    );
    let logininfo = await Api.doChainUserLogin(
      signMessage.uuid,
      signature,
      this.currentAddress
    );
    console.log(logininfo);

    this.getRewardInfo();
  }

  protected onAcitivtyReady(): void {
    this.activityReady = true;
    for (const waitFn of this.activityWaitQueue) {
      waitFn();
    }
    this.activityWaitQueue = [];
  }

  protected waitActivityReady(): Promise<void> | void {
    if (this.activityReady) {
      return;
    }

    return new Promise<void>((resolve) => {
      this.activityWaitQueue.push(resolve);
    });
  }

  //需要在派生类里面重载的方法，必须要写
  protected onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;

    // 钱包准备后，创建可写的合约对象
    this.heroContract = this.wallet.WritableBlockChain.createContract(
      ContractCfg.Badge.Contract.address,
      ContractCfg.Badge.Contract.abis
    );

    this.RewardCinfigContractR = this.wallet.WritableBlockChain.createContract(
      this.RewardCinfig.Contract.address,
      this.RewardCinfig.Contract.abis
    );
    this.refreshAllActivity();
    this.tologin();
  }

  // 相应账户变化
  protected async onAccountChg(): Promise<void> {
    await this.refreshAllActivity();
  }

  // 更新所有活动信息
  private async refreshAllActivity() {
    await this.waitActivityReady();

    if (this.currentAddress) {
      // 钱包授权，获取用户信息
      const promises: Promise<void>[] = [];
      promises.push(this.refreshHeroList());
      for (const activity of this.activityList) {
        promises.push(this.refreshActivity(activity));
      }
      await Promise.all(promises);
    } else {
      // 钱包未授权，清空所有活动信息
      for (const activity of this.activityList) {
        activity.data = this.getEmptyActivityData(activity.info.type);
      }
    }
  }

  private getEmptyActivityData(type: SellActivityType) {
    switch (type) {
      case SellActivityType.Sell:
        return {
          inWhiteList: false,
          buy: false,
          price: new BigNumber(0),
          approve: new BigNumber(0),
        } as SellActivityData;
      case SellActivityType.Exchange:
        return {
          buy: false,
          nftList: [],
        } as ExchangeActivityData;
      default:
        throw new Error(`Invalid sell activity type(${type})`);
    }
  }

  private refreshActivity(activity: ActivityInfo) {
    if (activity.info.type === SellActivityType.Sell) {
      return this.refreshSellActivity(activity);
    } else if (activity.info.type === SellActivityType.Exchange) {
      return this.refreshExchangeActivity(activity);
    } else {
      throw new Error(`Invalid sell activity type(${activity.info.type})`);
    }
  }

  private async refreshSellActivity(activity: ActivityInfo) {
    const sellContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.sellContract,
      SellAbi
    );

    const inContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC20Abi
    );

    const [whitelistResult, mintNum, price, approve] = await Promise.all([
      Api.inSellWhiteList(activity.info.id, this.currentAddress),
      this.methodCallToBN(
        sellContract.methods.getAccountMintAmount(this.currentAddress)
      ),
      this.methodCallToBN(sellContract.methods.getPrice()),
      this.methodCallToBN(
        inContract.methods.allowance(
          this.currentAddress,
          activity.info.sellContract
        )
      ),
    ]);

    activity.data = {
      inWhiteList: whitelistResult.IsInWhiteList,
      buy: mintNum.gt(0),
      price: price,
      approve: approve,
    };
    if (activity.info.id === 1) {
      this.activityList[0] = activity;
      var Drogonprice = Number(activity.data.price.toString()) / 1e18;
      this.DragonBoxPrice = Drogonprice.toString();
      this.offFrontDragonPrice = (Drogonprice / 0.8).toString();
    } else if (activity.info.id === 3) {
      this.activityList[1] = activity;
      var Mythicprice = Number(activity.data.price.toString()) / 1e18;
      this.MythicBoxPrice = Mythicprice.toString();
      this.offFrontMythicPrice = (Mythicprice / 0.8).toString();
    }
  }

  private async refreshExchangeActivity(activity: ActivityInfo) {
    const inContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const [nftNum] = await Promise.all([
      this.methodCallToInt(inContract.methods.balanceOf(this.currentAddress)),
    ]);

    const list: BigNumber[] = [];
    const promises: Promise<BigNumber>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promises.push(
        this.methodCallToBN(
          inContract.methods.tokenOfOwnerByIndex(this.currentAddress, i)
        )
      );

      if (promises.length >= 10 || i === nftNum - 1) {
        const nftIds = await Promise.all(promises);
        list.push(...nftIds);
      }
    }

    activity.data = {
      nftList: list,
    };
  }

  // 更新英雄列表
  private async refreshHeroList() {
    if (!this.currentAddress) {
      this.heroList = [];
      return;
    }

    const nftNum = await this.methodCallToInt(
      this.heroContractR.methods.balanceOf(this.currentAddress)
    );

    if (nftNum <= 0) {
      this.heroList = [];
      return;
    }

    const promises: Promise<HeroInfo>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promises.push(this.getHeroInfo(i));
    }

    this.heroList = await Promise.all(promises);
  }

  // 获取英雄信息
  private async getHeroInfo(index: number): Promise<HeroInfo> {
    const nftId = await this.methodCallToBN(
      this.heroContractR.methods.tokenOfOwnerByIndex(this.currentAddress, index)
    );

    const heroInfo = await Api.getHeroInfo(nftId);
    const cfg = typeof heroInfo === "string" ? JSON.parse(heroInfo) : heroInfo;
    return {
      nftId: nftId,
      cfg,
    };
  }

  // 授权合约扣除用户指定数量的代币
  protected async approve(index: number): Promise<void> {
    if (!this.currentAddress) return;
    if (!this.wallet.WritableBlockChain) return;
    if (this.isBuying) return;
    this.isBuying = true; // 点击一次上锁

    try {
      const activity = this.activityList[index];
      const data = activity.data as SellActivityData;
      if (data.approve.gte(data.price)) {
        return;
      }

      const inContract = this.wallet.WritableBlockChain.createContract(
        activity.info.inContract,
        ERC20Abi
      );

      const result = await inContract.methods
        .approve(
          activity.info.sellContract,
          data.price.minus(data.approve).toString(10)
        )
        .send();

      console.log(result);

      await this.refreshActivity(activity);
    } catch (error) {
      console.error(error);
    } finally {
      this.isBuying = false; // 解锁
    }
  }

  // 购买
  protected async buy(index: number): Promise<void> {
    if (!this.currentAddress) return;
    if (!this.wallet.WritableBlockChain) return;
    if (this.isBuying) return;
    this.isBuying = true; // 点击一次上锁

    try {
      const activity = this.activityList[index];
      const info = activity.info;
      const verifyMessage = await Api.getSellVerifyMessage(
        info.id,
        this.currentAddress
      );

      const signPersonalMessage = await this.wallet.signPersonalMessage(
        util.format(signMessage, verifyMessage.message),
        this.currentAddress
      );
      const transaction = await Api.getSellTransaction(
        info.id,
        this.currentAddress,
        signPersonalMessage
      );
      const sellContract = this.wallet.WritableBlockChain.createContract(
        info.sellContract,
        SellAbi
      );
      const result = await sellContract.methods
        .exchangeWithERC20Permit(
          transaction.caller,
          transaction.target,
          transaction.functionHash,
          transaction.deadline,
          transaction.v,
          transaction.r,
          transaction.s
        )
        .send();

      console.log(result);

      this.isShowPurchasedBox = true; // 右侧显示已购买提醒
      this.isShowBoughtRemindPanel = true; // 中间显示已购买面板
      await this.refreshActivity(activity);
    } catch (error) {
      console.error(error);
    } finally {
      this.isBuying = false; // 解锁
    }
  }

  private showChoosePanel(index: number) {
    const activity = this.activityList[index];
    const data = activity.data as ExchangeActivityData;
    this.currentChooseIndex = index;
    this.chooseNftList = data.nftList.map((nftId) => ({
      nftId,
      image: "https://nft.kingdomx.co/hero-image/FLY01030202010102.png",
      approve: false,
    }));
    this.showNftChoose = true;
  }

  private async exchange(index: number) {
    if (!this.currentAddress) {
      return;
    }

    if (!this.wallet.WritableBlockChain) {
      return;
    }

    const nftId = this.chooseNftList[index].nftId;
    const activity = this.activityList[this.currentChooseIndex];

    const inContract = this.wallet.WritableBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const address = await inContract.methods
      .getApproved(nftId.toString(10))
      .call();
    if (address !== inContract.address) {
      const result = await inContract.methods
        .approve(inContract.address, nftId.toString(10))
        .send();
      console.log(result);
    }

    const exchangeContract = this.wallet.WritableBlockChain.createContract(
      activity.info.sellContract,
      ExchangeAbi
    );

    const result = await exchangeContract.methods
      .exchangeKXHeroWithERC721Token(
        nftId.toString(10),
        this.currentAddress,
        "0x"
      )
      .send();

    console.log(result);

    await this.refreshActivity(activity);
  }

  // 进入收藏页面
  navToCollection(): void {
    this.$router.push({ name: "Collection" });
    this.isShowBoughtRemindPanel = false;
  }

  private openLink(url: string) {
    window.open(url, "_blank");
  }
}
