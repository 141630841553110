import BigNumber from "bignumber.js";
import BlockChain from "./block_chain";
import Contract from "./contract";
import { ChainConfig } from "./define";
import Method from "./method";
import MethodReadonly from "./method_readonly";
import ValueChangeListener, {
  ValueChangeHandler,
} from "./value_change_listener";

export type EmptyFunction = () => void;
export type AccountChangeHandler = ValueChangeHandler<string>;
export type NetworkChangeHandler = ValueChangeHandler<string>;

export type ContractInfo = {
  address: string;
  abis: unknown[];
};

type NamedContractInfo = {
  [name: string]: ContractInfo;
};

export type NamedContract<TMethod extends MethodReadonly> = {
  [name: string]: Contract<TMethod>;
};

export interface WalletOpts {
  nodeUrl: string; // 节点URL
  chainId: number; // 链id
  contracts: NamedContractInfo; // Key为名字，Value为合约信息的对象
}

abstract class Wallet {
  protected _nodeUrl: string; // 期望工作的节点URL
  protected _chainId: number; // 链id
  protected _namedContractInfo: NamedContractInfo; // Key为名字，Value为合约信息的对象
  protected _readonlyBlockChain: BlockChain<MethodReadonly>; // 默认BlockChain对象
  protected _writableBlockChain?: BlockChain<Method>; // 工作BlockChain对象
  protected _readonlyContracts: NamedContract<MethodReadonly> = {};
  protected _writableContracts: NamedContract<Method> = {};
  protected _walletReadyHandler: EmptyFunction[] = [];
  protected _accountChangeListener = new ValueChangeListener<string>();
  protected _networkChangeListener = new ValueChangeListener<string>();

  private _detectWalletTimer?: NodeJS.Timeout;
  private _accountChangeTimer?: NodeJS.Timeout;
  private _networkChangeTimer?: NodeJS.Timeout;
  private _lastAccount = "";
  private _lastChainId = "";

  public get IsWalletReady(): boolean {
    return this._writableBlockChain !== undefined;
  }
  public get ReadonlyBlockChain(): BlockChain<MethodReadonly> {
    return this._readonlyBlockChain;
  }
  public get ReadonlyContracts(): NamedContract<MethodReadonly> {
    return this._readonlyContracts;
  }
  public get WritableBlockChain(): BlockChain<Method> | undefined {
    return this._writableBlockChain;
  }
  public get WritableContracts(): NamedContract<Method> {
    return this._writableContracts;
  }

  // initialize start
  constructor(opts: WalletOpts) {
    this._nodeUrl = opts.nodeUrl;
    this._chainId = opts.chainId;
    this._namedContractInfo = opts.contracts;
    this._readonlyBlockChain = this.createReadonlyBlockChain();
    this.createAllContracts(this._readonlyContracts, this._readonlyBlockChain);
    this.startDetectWalletTimer();
  }

  private onWalletReady(blockChain: BlockChain<Method>) {
    this._writableBlockChain = blockChain;
    this.createAllContracts(this._writableContracts, this._writableBlockChain);
    this.invokeWalletReadyHandlers();
    this.startAccountChangeTimer();
    this.startNetworkChangeTimer();
  }

  private startDetectWalletTimer() {
    const timeout = 200;
    this._detectWalletTimer = setInterval(
      this.checkIfWalletReady.bind(this),
      timeout
    );
  }

  private stopDetectWalletTimer() {
    if (this._detectWalletTimer) {
      clearInterval(this._detectWalletTimer);
      this._detectWalletTimer = undefined;
    }
  }

  private async checkIfWalletReady() {
    const success = await this.detectWallet();
    if (success) {
      this.stopDetectWalletTimer();
    }
  }

  private async detectWallet(): Promise<boolean> {
    if (this.IsWalletReady) {
      return true;
    }

    const blockChain = await this.createWalletBlockChain();
    if (blockChain) {
      this.onWalletReady(blockChain);
      return true;
    }
    return false;
  }

  abstract createReadonlyBlockChain(): BlockChain<MethodReadonly>;
  abstract createWalletBlockChain():
    | Promise<BlockChain<Method> | undefined>
    | BlockChain<Method>
    | undefined;
  // initialize end

  // wallet ready handler start
  public addWalletReadyHandler(handler: EmptyFunction): void {
    if (this.IsWalletReady) {
      handler();
    } else {
      this._walletReadyHandler.push(handler);
    }
  }

  public addWalletReadyHandlers(handlersList: EmptyFunction[]): void {
    for (const handler of handlersList) {
      this.addWalletReadyHandler(handler);
    }
  }

  private invokeWalletReadyHandlers() {
    for (const handler of this._walletReadyHandler) {
      handler();
    }
    this._walletReadyHandler = [];
  }
  // wallet ready handler end

  // account start
  public abstract authorize(): Promise<boolean>;
  public abstract changeNetwork(cfg: ChainConfig): Promise<boolean>;
  public abstract getAddress(): string;
  public abstract getChainId(): string;
  public abstract signPersonalMessage(
    message: string,
    account?: string
  ): Promise<string>;

  public addAccountChangeHandler(handler: AccountChangeHandler): void {
    this._accountChangeListener.addChangeHandler(handler);
  }

  public addAccountChangeHandlers(handlersList: AccountChangeHandler[]): void {
    this._accountChangeListener.addChangeHandlers(handlersList);
  }

  private invokeAccountChangeHandlers(oldAccount: string, newAccount: string) {
    this._accountChangeListener.invokeChangeHandlers(oldAccount, newAccount);
  }

  public addNetworkChangeHandler(handler: NetworkChangeHandler): void {
    this._networkChangeListener.addChangeHandler(handler);
  }

  public addNetworkChangeHandlers(handlersList: NetworkChangeHandler[]): void {
    this._networkChangeListener.addChangeHandlers(handlersList);
  }

  private invokeNetworkChangeHandlers(oldNetwork: string, newNetwork: string) {
    this._networkChangeListener.invokeChangeHandlers(oldNetwork, newNetwork);
  }

  private startAccountChangeTimer() {
    this._lastAccount = this.getAddress();
    this._accountChangeTimer = setInterval(
      this.checkIfAccountChange.bind(this),
      1000
    );
  }

  private stopAccountChangeTimer() {
    if (this._accountChangeTimer) {
      clearInterval(this._accountChangeTimer);
      this._accountChangeTimer = undefined;
    }
  }

  private checkIfAccountChange() {
    const account = this.getAddress();
    if (this._lastAccount !== account) {
      this.invokeAccountChangeHandlers(this._lastAccount, account);
      this._lastAccount = account;
    }
  }

  private startNetworkChangeTimer() {
    this._lastChainId = this.getChainId();
    this._networkChangeTimer = setInterval(
      this.checkIfNetworkChange.bind(this),
      1000
    );
  }

  private stopNetworkChangeTimer() {
    if (this._networkChangeTimer) {
      clearInterval(this._networkChangeTimer);
      this._networkChangeTimer = undefined;
    }
  }

  private checkIfNetworkChange() {
    const chainId = this.getChainId();
    if (this._lastChainId !== chainId) {
      this.invokeNetworkChangeHandlers(this._lastChainId, chainId);
      this._lastChainId = chainId;
    }
  }
  // account end

  public toAtomic(num: BigNumber.Value): BigNumber {
    return new BigNumber(num).times("1e18").integerValue();
  }

  public fromAtomic(num: BigNumber.Value): BigNumber {
    return new BigNumber(num).div("1e18").integerValue();
  }

  private createAllContracts<TMethod extends MethodReadonly>(
    contracts: NamedContract<TMethod>,
    blockChain: BlockChain<TMethod>
  ) {
    Object.entries(this._namedContractInfo).forEach(([name, contractInfo]) => {
      const contract = blockChain.createContract(
        contractInfo.address,
        contractInfo.abis
      );
      contracts[name] = contract;
    });
  }
}

export default Wallet;
