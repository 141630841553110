import { Config } from "./define";
import ChainCfg from "./chain-config";
import {
  ERC20Abi,
  ERC721PermitAbi,
  MinerAbi,
  TokenLockAbi,
  UniswapV2RouterAbi,
  HeroAbi,
  LotteryAbi,
  BoxAbi,
  RewardAbi,
} from "./abi";

const Address = {
  KingToken: "0x52da44b5e584f730005dac8d2d2acbdee44d4ba3",
  LPToken: "0xa08b49787bbd9f958fd418a89d0d129427534c15",
};

const UniswapAddress = {
  BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  KT: Address.KingToken,
  KTBUSD_LP: Address.LPToken,
};

const bscMain = ChainCfg[56];
const bscTest = ChainCfg[97];
const defaultChain = bscMain;

const cfg: Config = {
  DefaultChain: defaultChain,
  UniswapRouter: {
    Chain: bscMain,
    Contract: {
      address: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      abis: UniswapV2RouterAbi,
    },
  },
  KingToken: {
    Chain: defaultChain,
    Contract: {
      address: Address.KingToken,
      abis: ERC20Abi,
    },
  },
  TokenLock: {
    Chain: defaultChain,
    Contract: {
      address: "0x2e4bb3D2bB7Dfddd6FeA08B468f8361758618b05",
      abis: TokenLockAbi,
    },
  },
  MinerPool: [
    {
      // King-King
      Chain: defaultChain,
      Miner: {
        address: "0x5705d03825BeA0A20Cb8FB2d05f60Ff216466516",
        abis: MinerAbi,
      },
      OutToken: {
        address: Address.KingToken,
        abis: ERC20Abi,
      },
      InToken: {
        address: Address.KingToken,
        abis: ERC20Abi,
      },
    },
    {
      // LP-King
      Chain: defaultChain,
      Miner: {
        address: "0x473AAe2Fa5199c31Bcf839440D8d465B617f63ff",
        abis: MinerAbi,
      },
      OutToken: {
        address: Address.KingToken,
        abis: ERC20Abi,
      },
      InToken: {
        address: Address.LPToken,
        abis: ERC20Abi,
      },
      Uniswap: {
        InTokenPrice: {
          IsLP: true,
          LPToken: UniswapAddress.KTBUSD_LP,
          DistToken: UniswapAddress.BUSD,
        },
        OutTokenPrice: {
          IsLP: false,
          TokenPath: [UniswapAddress.KT, UniswapAddress.BUSD],
        },
      },
    },
  ],
  Badge: {
    Chain: defaultChain,
    Contract: {
      address: "0x381D2a7924524428e7e240Da5686BDEf5D9Af8d4",
      abis: ERC721PermitAbi,
    },
  },
  Hero: {
    Chain: defaultChain,
    Contract: {
      address: "0x9a65E3193d9a27c834E31BFDbAe3E7B0759A4d67",
      abis: HeroAbi,
    },
  },
  Lottery: {
    Chain: defaultChain,
    Contract: {
      address: "",
      abis: LotteryAbi,
    },
  },
  NtfBox: {
    Chain: defaultChain,
    Contract: {
      address: "0xe4388c183931928A2F8C57F43d39DC51b969c1A6",
      abis: BoxAbi,
    },
  },
  PvpReward: {
    Chain: defaultChain,
    Contract: {
      // address: "0x7249ef7f13C17B7e0E514fF93deb7781A5B92528",
      address: "0x6bfd4b4d7a1c566d1584b93983e4b2a62ebdf05c",
      abis: RewardAbi,
    },
  },
};

export default cfg;
