















import { Component, Vue } from "vue-property-decorator";

import { onNavigate } from "@/logic/define";

@Component({
  name: "Navbar",
  props: {
    navNumber: {
      type: Number,
      required: true,
      validator(v: number): boolean {
        return Number.isInteger(v) && v > 0;
      },
    },
    activeIndex: {
      type: Number,
      default: 0,
      validator(v: number): boolean {
        return Number.isInteger(v) && v >= 0;
      },
    },
  },
})
export default class Navbar extends Vue {
  activeIndex = 0;

  onNavigate(index: number): void {
    onNavigate.call(this, index);
  }
}
