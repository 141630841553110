export type ValueChangeHandler<TVal> = (
  oldAccount: TVal,
  newAccount: TVal
) => void;

class ValueChangeListener<TVal> {
  protected changeHandler: ValueChangeHandler<TVal>[] = [];

  public addChangeHandler(handler: ValueChangeHandler<TVal>): void {
    this.changeHandler.push(handler);
  }

  public addChangeHandlers(handlersList: ValueChangeHandler<TVal>[]): void {
    for (const handler of handlersList) {
      this.addChangeHandler(handler);
    }
  }

  public invokeChangeHandlers(oldAccount: TVal, newAccount: TVal): void {
    for (const handler of this.changeHandler) {
      handler(oldAccount, newAccount);
    }
  }
}

export default ValueChangeListener;
