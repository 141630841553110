import BigNumber from "bignumber.js";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import "@metamask/detect-provider";

import BlockChain from "../abstract/block_chain";
import Contract from "../abstract/contract";
import { TransactionInfo } from "../abstract/define";
import Web3Contract from "./web3_contract";
import Web3MethodReadonly, { Web3MehotdNewer } from "./web3_method_readonly";

class Web3BlockChain<TMethod extends Web3MethodReadonly>
  implements BlockChain<TMethod>
{
  private readonly web3: Web3;
  private readonly fGetAccount: () => string;
  private readonly methodNewer: Web3MehotdNewer<TMethod>;
  private readonly mapContracts = new Map<string, Web3Contract<TMethod>>();

  constructor(
    web3: Web3,
    fGetAccount: () => string,
    methodNewer: Web3MehotdNewer<TMethod>
  ) {
    this.web3 = web3;
    this.fGetAccount = fGetAccount;
    this.methodNewer = methodNewer;
  }

  public get Web3(): Web3 {
    return this.web3;
  }

  public createContract(address: string, abis: AbiItem[]): Contract<TMethod> {
    let contract = this.mapContracts.get(address);
    if (contract) return contract;

    contract = new Web3Contract(
      this.web3,
      address,
      this.fGetAccount,
      this.methodNewer
    );
    contract.initFromAbis(abis);
    return contract;
  }

  public async createContractFromChain(
    address: string
  ): Promise<Contract<TMethod>> {
    let contract = this.mapContracts.get(address);
    if (contract) return contract;

    contract = new Web3Contract(
      this.web3,
      address,
      this.fGetAccount,
      this.methodNewer
    );
    await contract.initFromChain();
    return contract;
  }

  public async getBalance(address?: string): Promise<BigNumber> {
    if (address) {
      return new BigNumber(await this.web3.eth.getBalance(address));
    } else {
      return new BigNumber(await this.web3.eth.getBalance(this.fGetAccount()));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async transfer(
    to: string,
    amount: BigNumber,
    privateKey?: string
  ): Promise<TransactionInfo> {
    // this.web3.eth.sendTransaction({
    //   to: to,
    //   from: '',
    //   value: amount.toString(),
    // })
    throw new Error("Not implement");
  }

  public async getCurrentBlock(): Promise<number> {
    return this.web3.eth.getBlockNumber();
  }
}

export default Web3BlockChain;
