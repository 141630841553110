





































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageRoadmap",
  mounted(this: PageRoadmap) {
    this.imgs = this.$refs.imgs as HTMLDivElement;
  },
})
export default class PageRoadmap extends Vue {
  private imgs!: HTMLDivElement;
  private slideIndex = 0;
  private slideNum = 4;

  private slideNext(isNext: boolean) {
    const maxIndex = this.slideNum - 1;
    if (isNext) {
      if (this.slideIndex === maxIndex) {
        this.slideIndex = 0;
      } else {
        this.slideIndex++;
      }
    } else {
      if (this.slideIndex === 0) {
        this.slideIndex = maxIndex;
      } else {
        this.slideIndex--;
      }
    }
    this.modifyImg();
  }

  private modifyImg() {
    const imgs = this.imgs.children;
    const len = imgs.length;
    for (let i = 0; i < len; ++i) {
      let offset = i - this.slideIndex;
      if (offset < 0) offset += len;
      imgs[i].className = `order${offset % len}`;
    }
  }
}
