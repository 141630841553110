





















































































































































































import Web3 from "web3";
import { TransactionReceipt } from "web3-core";
import { Component, Mixins } from "vue-property-decorator";

import ContractCfg from "@/logic/contract-config";
import Contract from "@/logic/block_chain/abstract/contract";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Method from "@/logic/block_chain/abstract/method";
import { Api } from "@/logic/api";
import WalletMixin from "@/mixins/wallet";
import { DtoActivityInfo, SellActivityType } from "@/logic/api/dto/sell.dto";
import { ExchangeAbi, ERC721PermitAbi } from "@/logic/contract-config/abi";
import { DtoBoxInfo, DtoHeroInfo } from "@/logic/api/dto/dto";
import BigNumber from "bignumber.js";
import Web3BlockChain from "@/logic/block_chain/web3/web3_block_chain";
import Web3MethodReadonly from "@/logic/block_chain/web3/web3_method_readonly";

interface SellActivityData {
  inWhiteList: boolean;
  buy: boolean;
  price: BigNumber;
  approve: BigNumber;
}

interface ExchangeActivityData {
  nftList: BigNumber[];
}

interface ActivityInfo {
  info: DtoActivityInfo;
  data: SellActivityData | ExchangeActivityData;
}

interface HeroInfo {
  nftId: BigNumber;
  cfg: DtoHeroInfo;
}

interface NftChooseItem {
  nftId: BigNumber;
  info: DtoBoxInfo;
}

const TransferEventTopic = Web3.utils.sha3("Transfer(address,address,uint256)");

@Component({})
export default class Redeem extends Mixins(WalletMixin) {
  SellActivityType = SellActivityType;
  isShowOpenBoxShowHeroInfo = false; //点开宝箱后获得英雄的弹窗
  isShowRewardRemindPanel = false;
  isShowExchangeBoxDetailDialog = false; // 交换宝箱详情弹框
  isHideRemaind = false;

  resNum = 1;
  heroList: HeroInfo[] = [];
  loadedBoxInfo = false; //宝箱加载完成属性
  loadBoxInfo = true; //宝箱正在加载属性
  // wallet
  heroContractR!: Contract<MethodReadonly>;
  heroContract!: Contract<Method>;

  // business
  activityList: ActivityInfo[] = [];
  now = 0;

  // chooseNftList = [] as NftChooseItem[];
  chooseNftList: any = [];
  selectedNftBoxIndex = -1; // 选中的领取宝箱下标
  currentChooseIndex = 0; // choose hero
  isExchangeing = false; // 是否正在领取中

  public created(): void {
    // 创建只读合约对象，用于获取链上信息
    this.heroContractR = this.wallet.ReadonlyBlockChain.createContract(
      ContractCfg.Hero.Contract.address,
      ContractCfg.Hero.Contract.abis
    );

    // 添加账号变更的会带哦
    this.wallet.addAccountChangeHandler(this.onAccountChg.bind(this));
    this.onInit();
  }

  public async onInit(): Promise<void> {
    // 定时器刷新now
    setInterval(() => {
      this.now = Math.trunc(Date.now() / 1000);
    }, 1000);

    // 从中心化服务器，请求所有活动列表，但活动信息缺少与用户相关的信息
    const result = await Api.getSellActivityList();

    this.activityList = result.list
      .map((v) => ({
        info: v,
        data: this.getEmptyActivityData(v.type),
      }))
      // 过滤出领取的活动 type = 0，售卖； type = 1，领取；
      .filter((item) => {
        return item.info.type === 1;
      });

    this._getNftList(); // 获取 nft 宝箱列表
  }

  // 获取 nft 宝箱列表
  protected async _getNftList(): Promise<void> {
    let tempList = [];
    for (let activity of this.activityList) {
      const nftList = await this.refreshExchangeActivity(activity);
      if (nftList) tempList.push(...nftList);
    }
    this.chooseNftList = tempList;
    this.changeLoad();

    // 打开提箱面板
    if (!this.chooseNftList.length) this.isShowRewardRemindPanel = true;
  }

  protected onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;

    // 钱包准备后，创建可写的合约对象
    this.heroContract = this.wallet.WritableBlockChain.createContract(
      ContractCfg.Badge.Contract.address,
      ContractCfg.Badge.Contract.abis
    );

    this.refreshAllActivity();
  }

  // 相应账户变化
  protected async onAccountChg(): Promise<void> {
    await this.refreshAllActivity();
  }

  // 更新所有活动信息
  private async refreshAllActivity() {
    if (this.currentAddress) {
      this._getNftList(); // 获取 nft 宝箱列表
      this.loadedBoxInfo = false; //展示宝箱为空
    } else {
      // 钱包未授权，清空所有活动信息
      for (const activity of this.activityList) {
        activity.data = this.getEmptyActivityData(activity.info.type);
      }
    }
  }

  private getEmptyActivityData(type: SellActivityType) {
    switch (type) {
      case SellActivityType.Sell:
        return {
          inWhiteList: false,
          buy: false,
          price: new BigNumber(0),
          approve: new BigNumber(0),
        } as SellActivityData;
      case SellActivityType.Exchange:
        return {
          nftList: [],
        } as ExchangeActivityData;
      default:
        throw new Error(`Invalid sell activity type(${type})`);
    }
  }

  // redmeed 1.获取宝箱列表
  private async refreshExchangeActivity(activity: ActivityInfo) {
    if (!this.currentAddress) return;

    const inContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const [nftNum] = await Promise.all([
      this.methodCallToInt(inContract.methods.balanceOf(this.currentAddress)),
    ]);

    console.log(
      `Nft(${inContract.address}) owner(${this.currentAddress}) balanceOf(${nftNum})`
    );

    const list: BigNumber[] = [];
    let promisesA: Promise<BigNumber>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promisesA.push(
        this.methodCallToBN(
          inContract.methods.tokenOfOwnerByIndex(this.currentAddress, i)
        )
      );

      if (promisesA.length >= 10 || i === nftNum - 1) {
        const nftIds = await Promise.all(promisesA);
        list.push(...nftIds);
        promisesA = [];
      }
    }

    // console.log(list);

    const nftContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const getBoxData = async (
      contract: Contract<MethodReadonly>,
      nftId: BigNumber
    ): Promise<NftChooseItem> => ({
      nftId,
      info: await this.getNftInfo<DtoBoxInfo>(contract, nftId),
    });

    const promisesB: Promise<NftChooseItem>[] = [];
    for (const nftId of list) {
      promisesB.push(getBoxData(nftContract, nftId));
    }

    const listAry = await Promise.all(promisesB);

    return listAry.map((item: any) => {
      return {
        nftId: item.nftId,
        info: item.info,
        activityInfo: activity.info,
      };
    });
  }

  // 交换宝箱
  private async exchange() {
    if (!this.currentAddress) return;
    if (!this.wallet.WritableBlockChain) return;
    if (this.selectedNftBoxIndex < 0) return; // 没有选中的宝箱的下标
    if (this.isExchangeing) return;
    this.isExchangeing = true; // 点击一次上锁

    try {
      const nftId = this.chooseNftList[this.selectedNftBoxIndex].nftId;
      const activity =
        this.chooseNftList[this.selectedNftBoxIndex].activityInfo;

      const inContract = this.wallet.WritableBlockChain.createContract(
        activity.inContract,
        ERC721PermitAbi
      );

      const address = await inContract.methods
        .getApproved(nftId.toString(10))
        .call();
      if (address !== activity.sellContract) {
        const result = await inContract.methods
          .approve(activity.sellContract, nftId.toString(10))
          .send();
        console.log(result);
      }

      const exchangeContract = this.wallet.WritableBlockChain.createContract(
        activity.sellContract,
        ExchangeAbi
      );

      const result = await exchangeContract.methods
        .exchangeWithERC721(nftId.toString(10), this.currentAddress, "0x")
        .send();

      console.log("exchangeWithERC721", result);

      const receipt = await this.getTransactionReceipt(result);
      if (!receipt) {
        console.error(`Can not find transaction receipt for ${result}.`);
        return;
      }

      const nftIds = this.getIncomeNftsFromReceipt(
        receipt,
        this.currentAddress
      );

      //通过调用getHeroInfo将获得的新的英雄的信息写入
      if (nftIds.length > 0) {
        const promises: Promise<HeroInfo>[] = [];

        promises.push(this.getHeroInfo(nftIds[0]));
        this.heroList = await Promise.all(promises);
      }

      console.log(`Get new NFT [${nftIds.join(",")}]`);

      this._getNftList(); // 获取 nft 宝箱列表
      this.closeExchangeBoxDetailDialog();
      this.showHeroInfo(); //开启获取到的英雄详情的界面
    } catch (error) {
      console.error(error);
    } finally {
      this.isExchangeing = false; // 解锁
    }
  }

  //展示获取到新的英雄的信息
  private async showHeroInfo() {
    // const nftIds = ['1000229']

    // if(nftIds.length>0){
    // 		const promises: Promise<HeroInfo>[] = [];

    // 		promises.push(this.getHeroInfo(nftIds[0]));
    // 		this.heroList = await Promise.all(promises);
    // }

    this.isShowOpenBoxShowHeroInfo = true;
    this.isShowExchangeBoxDetailDialog = false;
    return;
  }

  private async closeHeroInfo() {
    this.isShowOpenBoxShowHeroInfo = false;
    return;
  }

  // 获取英雄信息
  private async getHeroInfo(nftId: string): Promise<HeroInfo> {
    const nftIds = new BigNumber(nftId);
    const heroInfo = await Api.getHeroInfo(nftIds);
    console.log(nftId);
    const cfg = typeof heroInfo === "string" ? JSON.parse(heroInfo) : heroInfo;
    console.log(cfg);
    return {
      nftId: nftIds,
      cfg,
    };
  }

  private async getTransactionReceipt(
    txHash: string
  ): Promise<TransactionReceipt | null> {
    const blockChain = this.wallet
      .ReadonlyBlockChain as Web3BlockChain<Web3MethodReadonly>;
    return blockChain.Web3.eth.getTransactionReceipt(txHash);
  }

  private getIncomeNftsFromReceipt(
    receipt: TransactionReceipt,
    receiver: string
  ) {
    receiver = receiver.toLowerCase();

    const nftIds = [] as string[];
    for (const log of receipt.logs) {
      const eventTopic = log.topics[0];
      if (eventTopic !== TransferEventTopic) {
        continue;
      }

      const nftReceiver = `0x${log.topics[2].substring(26)}`;
      const nftId = new BigNumber(log.topics[3]);
      console.log(receiver, nftReceiver, nftId.toString(10));
      if (nftReceiver !== receiver) {
        continue;
      }

      nftIds.push(nftId.toString(10));
    }
    return nftIds;
  }

  // 用于加载宝箱时页面显示的判断
  changeLoad(): void {
    if (this.chooseNftList.length != 0) {
      this.loadBoxInfo = false; //展示加载页面
    } else if (this.chooseNftList.length == 0 || !this.loadBoxInfo) {
      this.loadedBoxInfo = true; //展示宝箱为空
    }
  }

  // 打开领取宝箱弹框
  openExchangeBoxDetailDialog(nftIndex: number): void {
    this.selectedNftBoxIndex = nftIndex;

    this.isShowExchangeBoxDetailDialog = true;
  }

  // 关闭领取宝箱弹框
  closeExchangeBoxDetailDialog(): void {
    this.selectedNftBoxIndex = -1;
    this.isShowExchangeBoxDetailDialog = false;
  }
}
