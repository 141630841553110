







































































































import * as EthUtil from "ethereumjs-util";
import { Component, Mixins } from "vue-property-decorator";

import ContractCfg from "@/logic/contract-config";
import Contract from "@/logic/block_chain/abstract/contract";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Method from "@/logic/block_chain/abstract/method";
import { Api } from "@/logic/api";
import WalletMixin from "@/mixins/wallet";
import { DtoActivityInfo, SellActivityType } from "@/logic/api/dto/sell.dto";
import {
  ExchangeAbi,
  ERC20Abi,
  SellAbi,
  ERC721PermitAbi,
} from "@/logic/contract-config/abi";
import BigNumber from "bignumber.js";
import { Axios } from "axios";
import { DtoBoxInfo, DtoHeroInfo } from "@/logic/api/dto/dto";

interface SellActivityData {
  inWhiteList: boolean;
  buy: boolean;
  price: BigNumber;
  approve: BigNumber;
}

interface ExchangeActivityData {
  nftList: BigNumber[];
}

interface ActivityInfo {
  info: DtoActivityInfo;
  data: SellActivityData | ExchangeActivityData;
}

interface HeroInfo {
  nftId: BigNumber;
  info: DtoHeroInfo;
}

interface NftChooseItem {
  nftId: BigNumber;
  info: DtoBoxInfo;
}

@Component({
  name: "whitelist-test",
})
export default class WhiteListTest extends Mixins(WalletMixin) {
  SellActivityType = SellActivityType;

  // wallet
  heroContractR!: Contract<MethodReadonly>;
  heroContract!: Contract<Method>;

  // business
  activityReady = false;
  activityWaitQueue = [] as (() => void)[];
  activityList: ActivityInfo[] = [];
  heroList: HeroInfo[] = [];
  now = 0;
  axios = new Axios();

  // choose hero
  showNftChoose = false;
  currentChooseIndex = 0;
  chooseNftList = [] as NftChooseItem[];
  transferAddress = "";

  public created(): void {
    // 创建只读合约对象，用于获取链上信息
    this.heroContractR = this.wallet.ReadonlyBlockChain.createContract(
      ContractCfg.Hero.Contract.address,
      ContractCfg.Hero.Contract.abis
    );

    // 添加账号变更的会带哦
    this.wallet.addAccountChangeHandler(this.onAccountChg.bind(this));
    this.onInit();
  }

  public async onInit(): Promise<void> {
    // 定时器刷新now
    setInterval(() => {
      this.now = Math.trunc(Date.now() / 1000);
    }, 1000);

    // 从中心化服务器，请求所有活动列表，但活动信息缺少与用户相关的信息
    const result = await Api.getSellActivityList();
    this.activityList = result.list.map((v) => ({
      info: v,
      data: this.getEmptyActivityData(v.type),
    }));
    this.onAcitivtyReady();
  }

  protected onAcitivtyReady(): void {
    this.activityReady = true;
    for (const waitFn of this.activityWaitQueue) {
      waitFn();
    }
    this.activityWaitQueue = [];
  }

  protected waitActivityReady(): Promise<void> | void {
    if (this.activityReady) {
      return;
    }

    return new Promise<void>((resolve) => {
      this.activityWaitQueue.push(resolve);
    });
  }

  protected onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;

    // 钱包准备后，创建可写的合约对象
    this.heroContract = this.wallet.WritableBlockChain.createContract(
      ContractCfg.Hero.Contract.address,
      ContractCfg.Hero.Contract.abis
    );

    this.refreshAllActivity();
  }

  // 相应账户变化
  protected async onAccountChg(): Promise<void> {
    await this.refreshAllActivity();
  }

  // 更新所有活动信息
  private async refreshAllActivity() {
    await this.waitActivityReady();

    if (this.currentAddress) {
      // 钱包授权，获取用户信息
      const promises: Promise<void>[] = [];
      promises.push(this.refreshHeroList());
      for (const activity of this.activityList) {
        promises.push(this.refreshActivity(activity));
      }
      await Promise.all(promises);
    } else {
      // 钱包未授权，清空所有活动信息
      for (const activity of this.activityList) {
        activity.data = this.getEmptyActivityData(activity.info.type);
      }
    }
  }

  private getEmptyActivityData(type: SellActivityType) {
    switch (type) {
      case SellActivityType.Sell:
        return {
          inWhiteList: false,
          buy: false,
          price: new BigNumber(0),
          approve: new BigNumber(0),
        } as SellActivityData;
      case SellActivityType.Exchange:
        return {
          nftList: [],
        } as ExchangeActivityData;
      default:
        throw new Error(`Invalid sell activity type(${type})`);
    }
  }

  private refreshActivity(activity: ActivityInfo) {
    if (activity.info.type === SellActivityType.Sell) {
      return this.refreshSellActivity(activity);
    } else if (activity.info.type === SellActivityType.Exchange) {
      return this.refreshExchangeActivity(activity);
    } else {
      throw new Error(`Invalid sell activity type(${activity.info.type})`);
    }
  }

  private async refreshSellActivity(activity: ActivityInfo) {
    const sellContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.sellContract,
      SellAbi
    );

    const inContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC20Abi
    );

    const [whitelistResult, mintNum, price, approve] = await Promise.all([
      Api.inSellWhiteList(activity.info.id, this.currentAddress),
      this.methodCallToBN(
        sellContract.methods.getAccountMintAmount(this.currentAddress)
      ),
      this.methodCallToBN(sellContract.methods.getPrice()),
      this.methodCallToBN(
        inContract.methods.allowance(
          this.currentAddress,
          activity.info.sellContract
        )
      ),
    ]);

    activity.data = {
      inWhiteList: whitelistResult.IsInWhiteList,
      buy: mintNum.gt(0),
      price: price,
      approve: approve,
    };
  }

  // redmeed 1.获取宝箱列表
  private async refreshExchangeActivity(activity: ActivityInfo) {
    // const exchangeContract = this.wallet.ReadonlyBlockChain.createContract(
    //   activity.info.sellContract,
    //   ExchangeAbi
    // );

    const inContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const [nftNum] = await Promise.all([
      this.methodCallToInt(inContract.methods.balanceOf(this.currentAddress)),
    ]);

    const list: BigNumber[] = [];
    const promises: Promise<BigNumber>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promises.push(
        this.methodCallToBN(
          inContract.methods.tokenOfOwnerByIndex(this.currentAddress, i)
        )
      );

      if (promises.length >= 10 || i === nftNum - 1) {
        const nftIds = await Promise.all(promises);
        list.push(...nftIds);
      }
    }

    activity.data = {
      nftList: list,
    };
  }

  // 更新英雄列表
  private async refreshHeroList() {
    if (!this.currentAddress) {
      this.heroList = [];
      return;
    }

    const nftNum = await this.methodCallToInt(
      this.heroContractR.methods.balanceOf(this.currentAddress)
    );

    if (nftNum <= 0) {
      this.heroList = [];
      return;
    }

    const promises: Promise<HeroInfo>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promises.push(this.getHeroInfo(i));
    }

    this.heroList = await Promise.all(promises);
  }

  // 获取英雄信息
  private async getHeroInfo(index: number): Promise<HeroInfo> {
    const nftId = await this.methodCallToBN(
      this.heroContractR.methods.tokenOfOwnerByIndex(this.currentAddress, index)
    );

    const info = await this.getNftInfo<DtoHeroInfo>(this.heroContractR, nftId);
    return {
      nftId: nftId,
      info,
    };
  }

  private async getHeroInfoByURL(nftId: BigNumber) {
    const condition = false;
    if (condition) {
      return Api.getHeroInfo(nftId);
    }

    const url = (await this.heroContractR.methods
      .tokenURI(nftId.toString(10))
      .call()) as string;
    const rsp = await this.axios.get(url);
    if (rsp.status >= 200 && rsp.status < 300) {
      return rsp.data;
    } else {
      throw new Error(`Failed to get hero info(${url})`);
    }
  }

  // 授权合约扣除用户指定数量的代币
  protected async approve(index: number): Promise<void> {
    if (!this.currentAddress) {
      return;
    }

    if (!this.wallet.WritableBlockChain) {
      return;
    }

    const activity = this.activityList[index];
    const data = activity.data as SellActivityData;
    if (data.approve.gte(data.price)) {
      return;
    }

    const inContract = this.wallet.WritableBlockChain.createContract(
      activity.info.inContract,
      ERC20Abi
    );

    const result = await inContract.methods
      .approve(
        activity.info.sellContract,
        data.price.minus(data.approve).toString(10)
      )
      .send();

    console.log(result);

    await this.refreshActivity(activity);
  }

  // 购买
  protected async buy(index: number): Promise<void> {
    if (!this.currentAddress) {
      return;
    }

    if (!this.wallet.WritableBlockChain) {
      return;
    }

    const activity = this.activityList[index];
    const info = activity.info;
    const verifyMessage = await Api.getSellVerifyMessage(
      info.id,
      this.currentAddress
    );

    const signPersonalMessage = await this.wallet.signPersonalMessage(
      verifyMessage.message,
      this.currentAddress
    );

    const transaction = await Api.getSellTransaction(
      info.id,
      this.currentAddress,
      signPersonalMessage
    );

    const sellContract = this.wallet.WritableBlockChain.createContract(
      info.sellContract,
      SellAbi
    );

    const result = await sellContract.methods
      .exchangeWithERC20Permit(
        transaction.caller,
        transaction.target,
        transaction.functionHash,
        transaction.deadline,
        transaction.v,
        transaction.r,
        transaction.s
      )
      .send();

    console.log(result);

    await Promise.all([this.refreshActivity(activity), this.refreshHeroList()]);
  }

  private showChoosePanel(index: number) {
    const activity = this.activityList[index];
    const data = activity.data as ExchangeActivityData;
    this.currentChooseIndex = index;

    const nftContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const getBoxData = async (
      contract: Contract<MethodReadonly>,
      nftId: BigNumber
    ): Promise<NftChooseItem> => ({
      nftId,
      info: await this.getNftInfo<DtoBoxInfo>(contract, nftId),
    });

    const promises: Promise<NftChooseItem>[] = [];
    for (const nftId of data.nftList) {
      promises.push(getBoxData(nftContract, nftId));
    }

    Promise.all(promises).then((list) => {
      this.chooseNftList = list;
      this.showNftChoose = true;
    });
  }

  // 交换宝箱
  private async exchange(index: number) {
    if (!this.currentAddress) {
      return;
    }

    if (!this.wallet.WritableBlockChain) {
      return;
    }

    const nftId = this.chooseNftList[index].nftId;
    const activity = this.activityList[this.currentChooseIndex];

    const inContract = this.wallet.WritableBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const address = await inContract.methods
      .getApproved(nftId.toString(10))
      .call();
    if (address !== activity.info.sellContract) {
      const result = await inContract.methods
        .approve(activity.info.sellContract, nftId.toString(10))
        .send();
      console.log(result);
    }

    const exchangeContract = this.wallet.WritableBlockChain.createContract(
      activity.info.sellContract,
      ExchangeAbi
    );

    const result = await exchangeContract.methods
      .exchangeWithERC721(nftId.toString(10), this.currentAddress, "0x")
      .send();

    console.log(result);

    await Promise.all([this.refreshActivity(activity), this.refreshHeroList()]);
  }

  private async transferBox(index: number) {
    if (!this.currentAddress) {
      return;
    }

    if (!this.wallet.WritableBlockChain) {
      return;
    }

    if (!EthUtil.isValidAddress(this.transferAddress)) {
      alert("Please input a valid address");
      return;
    }

    const nftId = this.chooseNftList[index].nftId;
    const activity = this.activityList[this.currentChooseIndex];

    const inContract = this.wallet.WritableBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const result = await inContract.methods
      .transferFrom(
        this.currentAddress,
        this.transferAddress,
        nftId.toString(10)
      )
      .send();

    console.log(result);

    await Promise.all([this.refreshActivity(activity)]);
    this.showChoosePanel(this.currentChooseIndex);
  }

  private async transferHero(index: number) {
    if (!this.currentAddress) {
      return;
    }

    if (!this.heroContract) {
      return;
    }

    if (!EthUtil.isValidAddress(this.transferAddress)) {
      alert("Please input a valid address");
      return;
    }

    const nftId = this.heroList[index].nftId;
    const result = await this.heroContract.methods
      .transferFrom(
        this.currentAddress,
        this.transferAddress,
        nftId.toString(10)
      )
      .send();

    console.log(result);

    this.refreshHeroList();
  }
}
