

























import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "BgVideoPlayer",
})
export default class BgVideoPlayer extends Vue {
  private isPC = true;
  private isFirst = true;

  public showVideo(isPC: boolean, isFirst: boolean): void {
    this.isPC = isPC;
    this.isFirst = isFirst;
    console.log(this.isPC, this.isFirst);
  }
}
