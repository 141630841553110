import { TransactionReceipt } from "web3-core";
import { EventData as EthEventData } from "web3-eth-contract";

import { MethodSendOpt } from "../abstract/define";
import Method from "../abstract/method";
import Web3MethodReadonly from "./web3_method_readonly";

class Web3Method extends Web3MethodReadonly implements Method {
  send(
    opt?: MethodSendOpt & { returnReceipt: true }
  ): Promise<TransactionReceipt>;
  send(opt?: MethodSendOpt & { returnReceipt?: false }): Promise<string>;
  public async send(opt?: MethodSendOpt): Promise<string | TransactionReceipt> {
    try {
      const gasLimit = await this.estimateGas(opt);
      const receipt = (await this.ethContract.methods[this.name](
        ...this.args
      ).send({
        gasPrice: this.gasPric,
        gas: gasLimit.times(1.5).integerValue(),
        value: opt?.transferValue?.toString(),
        from: this.callerGetter(),
      })) as TransactionReceipt;

      if (opt?.returnReceipt) {
        return receipt;
      } else {
        return receipt.transactionHash;
      }
    } catch (e) {
      const error = e as Error;
      error.message =
        `Failed to send contract method ${this.name}. contract: ${
          this.ethContract.options.address
        } Args: [${this.args.join(", ")}].\n` + error.message;
      throw error;
    }
  }
}

export default Web3Method;
