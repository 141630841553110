

























































import { Component, Mixins } from "vue-property-decorator";
import BigNumber from "bignumber.js";

import MinerMixin from "@/mixins/miner";
import * as util from "@/logic/core/util";

interface ImgCfg {
  idle: string;
  work: string;
}

const ImgCfgList: ImgCfg[] = [
  {
    idle: require("@/assets/miner/miner1_idle.webp"),
    work: require("@/assets/miner/miner1_work.webp"),
  },
  {
    idle: require("@/assets/miner/miner2_idle.webp"),
    work: require("@/assets/miner/miner2_work.webp"),
  },
];

@Component({
  name: "MinerPage",
  props: {
    ImgIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
})
// export default class Miner extends Vue {
export default class Miner extends Mixins(MinerMixin) {
  // props
  ImgIndex!: number;

  imgCfg!: ImgCfg;

  totalSupply = new BigNumber(0);
  myReward = new BigNumber(0);
  APY = 0;

  allowanceToken = new BigNumber(0);
  stakedToken = new BigNumber(0);
  LPToken = "";

  tick = true;

  public created(): void {
    this.imgCfg = ImgCfgList[this.ImgIndex];
    if (this.PoolInfo.Uniswap?.InTokenPrice?.IsLP) {
      this.LPToken = this.PoolInfo.Uniswap.InTokenPrice.LPToken;
    }

    this.startTick();
  }

  public beforeDestroy(): void {
    this.tick = false;
  }

  protected async startTick(): Promise<void> {
    while (this.tick) {
      await Promise.all([this.refreshGlobalInfo(), this.refreshPersonalInfo()]);
      await util.waitForMs(3000);
    }
  }

  protected async refreshGlobalInfo(): Promise<void> {
    let apy: BigNumber;
    [this.totalSupply, apy] = await Promise.all([
      this.getTotalSupply(),
      this.getApy(),
    ]);

    this.APY = this.bnToRatio(apy);
  }

  protected async refreshPersonalInfo(): Promise<void> {
    if (!this.currentAddress) {
      this.allowanceToken = new BigNumber(0);
      this.stakedToken = new BigNumber(0);
      this.myReward = new BigNumber(0);
      return;
    }

    [this.allowanceToken, this.stakedToken, this.myReward] = await Promise.all([
      this.allowance(this.currentAddress),
      this.getStakedToken(this.currentAddress),
      this.getUserReward(this.currentAddress),
    ]);
  }

  private async startApprove() {
    await this.approve();
    this.allowanceToken = await this.allowance(this.currentAddress);
  }
}
