

























import { Component, Vue } from "vue-property-decorator";
import WalletManager from "@/logic/block_chain/wallet-manager";
import Wallet from "@/logic/block_chain/abstract/wallet";
import ContractCfg from "@/logic/contract-config";
import Contract from "@/logic/block_chain/abstract/contract";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Method from "@/logic/block_chain/abstract/method";
import { Api } from "@/logic/api";

@Component({
  name: "badge_test",
})
export default class BadgeTest extends Vue {
  // wallet
  currentWalletAddr = "";
  currentChainId = "";
  isLogined = false;
  expectChaindId = ContractCfg.Badge.Chain.chainId;
  wallet!: Wallet;
  badgeContractR!: Contract<MethodReadonly>;
  badgeContract!: Contract<Method>;

  // server
  currentServerAddr = "";

  public created(): void {
    this.wallet = WalletManager.getWallet(ContractCfg.Badge.Chain);

    this.badgeContractR = this.wallet.ReadonlyBlockChain.createContract(
      ContractCfg.Badge.Contract.address,
      ContractCfg.Badge.Contract.abis
    );

    this.wallet.addWalletReadyHandler(this.onWalletReady.bind(this));
  }

  private async login() {
    try {
      const accout = this.currentWalletAddr;
      const signMessage = await Api.getSignMessage();
      const signature = await this.wallet.signPersonalMessage(
        signMessage.signMessage,
        accout
      );

      console.log("signature", signature);

      await Api.doLogin({
        uuid: signMessage.uuid,
        signature: signature,
        owner: accout,
      });

      await this.tryLogin();
    } catch (e) {
      console.error(e);
      alert("Failed to login.");
    }
  }

  private onWalletReady() {
    if (!this.wallet.WritableBlockChain) return;

    this.currentWalletAddr = this.wallet.getAddress();
    this.currentChainId = this.wallet.getChainId();

    this.badgeContract = this.wallet.WritableBlockChain.createContract(
      ContractCfg.Badge.Contract.address,
      ContractCfg.Badge.Contract.abis
    );

    this.wallet.addAccountChangeHandler((oldVal, newVal) => {
      this.currentWalletAddr = newVal;
      if (this.currentWalletAddr !== "") {
        this.refreshPersonalInfo();
      }
    });

    this.wallet.addNetworkChangeHandler((oldVal, newVal) => {
      this.currentChainId = newVal;
    });

    this.tryLogin();
  }

  private async refreshPersonalInfo(): Promise<void> {
    const num = Number.parseInt(
      (await this.badgeContractR.methods.balanceOf().call()) as string
    );

    const getNftIdByIndex = async (owner: string, i: number) => {
      return (await this.badgeContractR.methods
        .tokenOfOwnerByIndex(owner, i)
        .call()) as string;
    };

    const promises: Promise<string>[] = [];
    for (let i = 0; i < num; ++i) {
      promises.push(getNftIdByIndex(this.currentWalletAddr, i));
    }

    const nftList = await Promise.all(promises);
  }

  private async tryLogin() {
    Api.isLogined().then((user) => {
      if (user) {
        this.isLogined = true;
        this.refreshLoginUserInfo();
      }
    });
  }

  private async refreshLoginUserInfo(): Promise<void> {
    const enabledActivityList = await Api.getEnabledActivityList();
  }

  // wallet interface
  protected async authorize(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      alert("Please install MetaMask first.");
      return;
    }

    if (await this.wallet.authorize()) {
      this.onWalletReady();
    } else {
      alert("Need authorize in MetaMask");
    }
  }

  protected async switchNetwork(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      alert("Please install MetaMask first.");
      return;
    }

    if (this.currentChainId !== ContractCfg.Badge.Chain.chainId) {
      this.wallet.changeNetwork(ContractCfg.Badge.Chain);
    }
  }
}
