



































import { Component, Vue, Mixins } from "vue-property-decorator";
import WalletMixin from "@/mixins/wallet";
import { Api } from "@/logic/api";

@Component({
  name: "download-page",
})
export default class donwLoad extends Mixins(WalletMixin) {
  //需要在派生类里面重载的方法，必须要写
  protected onWalletReady(): Promise<void> | void {
    console.log("load sucessful!");
  }
}
