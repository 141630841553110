import { Config } from "./define";
import ChainCfg from "./chain-config";
import {
  ERC20Abi,
  ERC721PermitAbi,
  MinerAbi,
  TokenLockAbi,
  UniswapV2RouterAbi,
  HeroAbi,
  LotteryAbi,
  BoxAbi,
  RewardAbi,
} from "./abi";

const Address = {
  KingToken: "0x41bA8c805FB21039E90f762E8446609311Bd0e03",
  LPToken: "0xEf6D88310713C206A11c282154dD73e6a52a1e44",
};

const UniswapAddress = {
  BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  KT: "0x52da44b5e584f730005dac8d2d2acbdee44d4ba3",
  KTBUSD_LP: "0xa08b49787bbd9f958fd418a89d0d129427534c15",
};

const bscMain = ChainCfg[56];
const bscTest = ChainCfg[97];
const defaultChain = bscTest;

const cfg: Config = {
  DefaultChain: defaultChain,
  UniswapRouter: {
    Chain: bscMain,
    Contract: {
      address: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      abis: UniswapV2RouterAbi,
    },
  },
  KingToken: {
    Chain: defaultChain,
    Contract: {
      address: Address.KingToken,
      abis: ERC20Abi,
    },
  },
  TokenLock: {
    Chain: defaultChain,
    Contract: {
      address: "0x2e4bb3D2bB7Dfddd6FeA08B468f8361758618b05",
      abis: TokenLockAbi,
    },
  },
  MinerPool: [
    {
      // King-King
      Chain: defaultChain,
      Miner: {
        address: "0x377d05c5b252F03680f65437952bC37E9e449174",
        abis: MinerAbi,
      },
      OutToken: {
        address: Address.KingToken,
        abis: ERC20Abi,
      },
      InToken: {
        address: Address.KingToken,
        abis: ERC20Abi,
      },
    },
    {
      // LP-King
      Chain: defaultChain,
      Miner: {
        address: "0xdB97F98094Cb39115D2a2770fdC7c4B28a3a5ceC",
        abis: MinerAbi,
      },
      OutToken: {
        address: Address.KingToken,
        abis: ERC20Abi,
      },
      InToken: {
        address: Address.LPToken,
        abis: ERC20Abi,
      },
      Uniswap: {
        InTokenPrice: {
          IsLP: true,
          LPToken: UniswapAddress.KTBUSD_LP,
          DistToken: UniswapAddress.BUSD,
        },
        OutTokenPrice: {
          IsLP: false,
          TokenPath: [UniswapAddress.KT, UniswapAddress.BUSD],
        },
      },
    },
  ],
  Badge: {
    Chain: defaultChain,
    Contract: {
      address: "0x381D2a7924524428e7e240Da5686BDEf5D9Af8d4",
      abis: ERC721PermitAbi,
    },
  },
  Hero: {
    Chain: defaultChain,
    Contract: {
      address: "0x6D142607c7165fA78DC3a15Ed4B6dD1F287f3a10",
      abis: HeroAbi,
    },
  },
  Lottery: {
    Chain: defaultChain,
    Contract: {
      address: "",
      abis: LotteryAbi,
    },
  },
  NtfBox: {
    Chain: defaultChain,
    Contract: {
      address: "0xE7710720f98aBdF7381D477D5709Af8aAA1bAe4C",
      abis: BoxAbi,
    },
  },
  PvpReward: {
    Chain: defaultChain,
    Contract: {
      address: "0x256E264E017891Cc7b684b306a56887C7B0b4EF4",
      abis: RewardAbi,
    },
  },
};

export default cfg;
