const bscNetwork = {
  chainId: "0x38",
  chainName: "Binance Smart Chain Mainnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "bnb",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-dataseed1.binance.org/"],
  blockExplorerUrls: ["https://bscscan.com/"],
};
const token = {
  symbol: "KT",
  image: "https://resource.kingdomx.co/kingtoken_icon.png",
  address: "0x52da44b5e584f730005dac8d2d2acbdee44d4ba3",
  decimals: 18,
};

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum as any;

  if (provider) {
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [bscNetwork],
      });
      return true;
    } catch (error) {
      console.error("Failed to setup the network in Metamask:", error);
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async () => {
  const provider = window.ethereum as any;
  if (provider) {
    const tokenAdded = await provider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: token,
      },
    });

    return tokenAdded;
  }
};
