






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "FullMask",
})
export default class FullMask extends Vue {}
