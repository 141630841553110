

















import { Component, Vue } from "vue-property-decorator";
import Spine from "@/Spine";

@Component({
  name: "PageOverview",
  mounted(this: PageOverview) {
    this.initSpine();
  },
})
export default class PageOverview extends Vue {
  private spine?: Spine;

  public setSpineRendering(isRendering: boolean): void {
    if (this.spine) {
      this.spine.IsRendering = isRendering;
    }
  }

  private initSpine() {
    const canvas = this.$refs.Spine as HTMLCanvasElement;
    const aspect = window.innerWidth / window.innerHeight;
    const isLandscape = aspect > 4 / 3;
    const scale = isLandscape ? 0.4 : 0.25;
    const x = isLandscape ? -150 : 0;
    const y = isLandscape ? -150 : 0;
    this.spine = new Spine({
      canvas,
      pathPrefix: "assets/pc/spine/",
      skeletonFile: "knights.json",
      animation: "newAnimation",
      spienScale: scale,
      spinePosition: { x: x, y: y },
      onLoaded: this.onSpineLoaded.bind(this),
      // debug: true,
    });

    this.spine.startLoad();
  }

  private onSpineLoaded() {
    const eleList: HTMLElement[] = [];
    if (this.spine) {
      this.spine.IsRendering = true;
      eleList.push(this.spine.canvas);
    }

    const fog = this.$refs.FirstFog as HTMLElement | undefined;
    if (fog) {
      eleList.push(fog);
    }

    if (eleList.length > 0) {
      setTimeout(
        (list: HTMLElement[]) => {
          for (const ele of list) {
            ele.classList.add("show");
          }
        },
        200,
        eleList
      );
    }
  }
}
