import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomePage from "../views/home-page.vue";
import MinerPage from "../views/miner-page.vue";
import MinerOldPage from "../views/miner-old-page.vue";
import UnlockPage from "../views/unlock-page.vue";
import CampaignPage from "../views/campaign-page.vue";
import CampaignHeroPage from "../views/campaign-hero-page.vue";
import ClaimPage from "../views/claim-page.vue";
import CollectionPage from "../views/collection-page.vue";
import RedeemPage from "../views/redeem-page.vue";
import BadgeTest from "../views/badge-test.vue";
import WhiteListTest from "../views/whitelist-test.vue";
import LoginPage from "../views/login-page.vue";
import ALPHA from "../views/alpha-page.vue";
import Raffle from "../views/raffle-page.vue";
import DownLoad from "../views/download-page.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/miner",
    name: "Miner",
    // component: MinerPage,
    component: MinerOldPage,
  },
  {
    path: "/unlock",
    name: "Unlock",
    component: UnlockPage,
  },
  {
    path: "/campaign",
    name: "Campaign",
    component: CampaignPage,
  },
  {
    path: "/claim",
    name: "Claim",
    component: ClaimPage,
  },
  {
    path: "/campaign-hero",
    name: "CampaignHero",
    component: CampaignHeroPage,
  },
  {
    path: "/collection",
    name: "Collection",
    component: CollectionPage,
  },
  {
    path: "/redeem",
    name: "Redeem",
    component: RedeemPage,
  },
  {
    path: "/badgeTest",
    name: "BadgeTest",
    component: BadgeTest,
  },
  {
    path: "/whitelistTest",
    name: "WhiteListTest",
    component: WhiteListTest,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/alpha",
    name: "Alpha",
    component: ALPHA,
  },
  {
    path: "/raffle",
    name: "Raffle",
    component: Raffle,
  },
  {
    path: "/DownLoad",
    name: "DownLoad",
    component: DownLoad,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
