import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import BigNumber from "bignumber.js";

import ContractCfg from "@/logic/contract-config";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Contract from "@/logic/block_chain/abstract/contract";
import Wallet from "@/logic/block_chain/abstract/wallet";
import WalletManager from "@/logic/block_chain/wallet-manager";
import { ChainConfig } from "@/logic/block_chain/abstract/define";
import { ChainConfigValidator } from "@/logic/contract-config/validator";

@Component({
  props: {
    Chain: {
      type: Object,
      required: false,
      default: () => ContractCfg.DefaultChain,
      validator: ChainConfigValidator,
    },
  },
})
export default class WalletMixin extends Vue {
  // wallet
  Chain!: ChainConfig;
  wallet!: Wallet;

  currentAddress = "";
  currentChainId = "";

  public created(): void {
    this.wallet = WalletManager.getWallet(this.Chain);

    this.wallet.addWalletReadyHandler(this._onWalletReady.bind(this));

    this.wallet.addAccountChangeHandler((oldVal, newVal) => {
      this.currentAddress = newVal;
    });

    this.wallet.addNetworkChangeHandler((oldVal, newVal) => {
      this.currentChainId = newVal;
    });
  }

  // override in derivea class
  protected onWalletReady(): Promise<void> | void {
    throw new Error("WalletMixins::onWalletReady not implement in child class");
  }

  private _onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;

    this.currentAddress = this.wallet.getAddress();
    this.currentChainId = this.wallet.getChainId();

    this.onWalletReady();
  }

  // base interface
  protected async methodCallToInt(method: MethodReadonly): Promise<number> {
    return Number.parseInt((await method.call()) as string);
  }

  protected async methodCallToBN(method: MethodReadonly): Promise<BigNumber> {
    return new BigNumber((await method.call()) as BigNumber.Value);
  }

  protected bnToRatio(bn: BigNumber): number {
    return bn.times(10000).integerValue().toNumber();
  }

  // wallet interface
  protected async authorize(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      alert("Please install MetaMask first.");
      return;
    }

    if (await this.wallet.authorize()) {
      this._onWalletReady();
    } else {
      alert("Need authorize in MetaMask");
    }
  }

  protected async switchNetwork(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      alert("Please install MetaMask first.");
      return;
    }

    if (this.currentChainId !== this.Chain.chainId) {
      this.wallet.changeNetwork(this.Chain);
    }
  }

  protected async balanceOf(
    account: string,
    contractR: Contract<MethodReadonly>
  ): Promise<BigNumber> {
    return this.methodCallToBN(contractR.methods.balanceOf(account));
  }

  protected async getNftInfo<T>(
    contract: Contract<MethodReadonly>,
    nftId: BigNumber
  ): Promise<T> {
    const url = (await contract.methods
      .tokenURI(nftId.toString(10))
      .call()) as string;
    // const url = `http://localhost:3000/hero/${nftId.toString(10)}`;
    const rsp = await axios.get<T>(url);
    if (rsp.status >= 200 && rsp.status < 300) {
      return rsp.data;
    }

    throw new Error(
      `Request Nft(${nftId.toString(10)}) in contract(${
        contract.address
      }) failed.`
    );
  }
}
