








































































































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Campaign extends Vue {
  isShowMetaMaskPanel = false;
  // isShowMetaMaskPanel = false;
  isShowRewardRemindPanel = false;

  openLink(url: string): void {
    window.open(url, "_blank");
  }

  navToClaim(): void {
    this.$router.push({ name: "Claim" });
  }
}
