




















































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Claim extends Vue {
  navToCollection(): void {
    this.$router.push({ name: "Collection" });
  }
}
