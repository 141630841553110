








































































import { Component, Vue } from "vue-property-decorator";
import BigNumber from "bignumber.js";

import ContractCfg from "@/logic/contract-config";
import Wallet from "@/logic/block_chain/abstract/wallet";
import WalletManager from "@/logic/block_chain/wallet-manager";
import Contract from "@/logic/block_chain/abstract/contract";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Method from "@/logic/block_chain/abstract/method";

enum Status {
  Lock,
  Unlock,
  Claimed,
}

interface Info {
  Time: number;
  Percent: BigNumber;
}

const RATIO_BASE = new BigNumber("1e18");

@Component({
  name: "MinerOperator",
})
export default class MinerOperator extends Vue {
  Status = Status;
  list: Info[] = [];
  tokenDecimal = 18;

  // wallet
  TokenLockConfig = ContractCfg.TokenLock;
  wallet!: Wallet;
  lockContractR!: Contract<MethodReadonly>;
  lockContract!: Contract<Method>;

  currentAddress = "";
  currentChainId = "";

  now = 0;
  // 第一期开始时间
  startTime = 0;
  // 第一期比例，万分比
  firstReleaseRate = new BigNumber(0);
  // 总期数，不算第一期
  totalStage = 0;
  // 每一期的时间
  stageTime = 0;
  // 余下期数比例，万分比
  releaseRatePerStage = new BigNumber(0);
  // 用户从最开始就锁住的token数量
  ownerLockToken = new BigNumber(0);
  // 用户已经提取的token数量
  ownerWithdrawToken = new BigNumber(0);
  // 用户当前可以领取的token数量
  ownerReleaseToken = new BigNumber(0);

  private async created() {
    this.wallet = WalletManager.getWallet(this.TokenLockConfig.Chain);

    this.lockContractR = this.wallet.ReadonlyBlockChain.createContract(
      this.TokenLockConfig.Contract.address,
      this.TokenLockConfig.Contract.abis
    );

    this.wallet.addWalletReadyHandler(this.onWalletReady.bind(this));

    this.wallet.addAccountChangeHandler((oldVal, newVal) => {
      this.currentAddress = newVal;
      if (this.currentAddress !== "") {
        this.refreshPersonalInfo();
      }
    });

    this.wallet.addNetworkChangeHandler((oldVal, newVal) => {
      this.currentChainId = newVal;
    });

    this.refreshGlobalInfo();

    setInterval(() => {
      this.now = Math.trunc(Date.now() / 1000);
    }, 500);
  }

  private async mounted() {
    //
  }

  private async refreshGlobalInfo(): Promise<void> {
    [this.startTime, this.firstReleaseRate, this.totalStage, this.stageTime] =
      await Promise.all([
        this.methodCallToInt(this.lockContractR.methods._startTime()),
        this.methodCallToBN(this.lockContractR.methods._firstReleaseRate()),
        this.methodCallToInt(this.lockContractR.methods._totalStage()),
        this.methodCallToInt(this.lockContractR.methods._stageTime()),
      ]);

    this.releaseRatePerStage = RATIO_BASE.minus(this.firstReleaseRate)
      .div(this.totalStage)
      .integerValue();
    this.calcList();
  }

  private async refreshPersonalInfo(): Promise<void> {
    [this.ownerLockToken, this.ownerWithdrawToken, this.ownerReleaseToken] =
      await Promise.all([
        this.methodCallToBN(
          this.lockContractR.methods.getOwnerLockToken(this.currentAddress)
        ),
        this.methodCallToBN(
          this.lockContractR.methods.getOwnerWithdrawToken(this.currentAddress)
        ),
        this.methodCallToBN(
          this.lockContractR.methods.getOwnerReleaseToken(this.currentAddress)
        ),
      ]);
  }

  private onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;

    this.currentAddress = this.wallet.getAddress();
    this.currentChainId = this.wallet.getChainId();

    this.lockContract = this.wallet.WritableBlockChain.createContract(
      this.TokenLockConfig.Contract.address,
      this.TokenLockConfig.Contract.abis
    );
  }

  private async withdrawReleaseToken(): Promise<void> {
    const txId = await this.lockContract.methods
      .withdrawReleaseToken(this.currentAddress)
      .send({});
    console.log(txId);
    await this.refreshPersonalInfo();
  }

  private calcList() {
    this.list = [];
    this.list.push({
      Time: this.startTime,
      Percent: this.firstReleaseRate,
    });

    for (let i = 0; i < this.totalStage; ++i) {
      let stage = i + 1;
      this.list.push({
        Time: this.startTime + this.stageTime * stage,
        Percent: this.firstReleaseRate.plus(
          this.releaseRatePerStage.times(stage)
        ),
      });
    }

    return;
  }

  // wallet interface
  protected async authorize(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      alert("Please install MetaMask first.");
      return;
    }

    if (await this.wallet.authorize()) {
      this.onWalletReady();
    } else {
      alert("Need authorize in MetaMask");
    }
  }

  protected async switchNetwork(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      alert("Please install MetaMask first.");
      return;
    }

    if (this.currentChainId !== this.TokenLockConfig.Chain.chainId) {
      this.wallet.changeNetwork(this.TokenLockConfig.Chain);
    }
  }

  // base interface
  private async methodCallToInt(method: MethodReadonly): Promise<number> {
    return Number.parseInt((await method.call()) as string);
  }

  private async methodCallToBN(method: MethodReadonly): Promise<BigNumber> {
    return new BigNumber((await method.call()) as BigNumber.Value);
  }

  private getStageAmount(stage: number): BigNumber {
    if (this.ownerLockToken.lte(0)) return this.ownerLockToken;

    const percent =
      stage === 0 ? this.firstReleaseRate : this.releaseRatePerStage;
    return this.ownerLockToken.times(percent).div(RATIO_BASE).integerValue();
  }

  private getStatus(stage: number, now: number): Status {
    if (now < this.list[stage].Time) return Status.Lock;

    if (this.ownerLockToken.lte(0)) return Status.Unlock;

    const withdrawPercent = this.ownerWithdrawToken
      .times(RATIO_BASE)
      .div(this.ownerLockToken)
      .integerValue();
    if (withdrawPercent.gte(this.list[stage].Percent)) return Status.Claimed;
    return Status.Unlock;
  }
}
