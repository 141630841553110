




























































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import WalletMixin from "@/mixins/wallet";
import { Api } from "@/logic/api";

@Component({})
export default class Alpha extends Mixins(WalletMixin) {
  status = 0; //当前地址的状态
  isShowWiting = false; //显示正在进行操作的弹窗
  isShowSuccess = false; //显示领取成功的弹窗
  isShowFaild = false; //显示领取失败的弹窗
  listenStatus = true; //监听资产状态
  listenNum = 0;
  // eslint-disable-next-line no-undef
  timer: NodeJS.Timeout | undefined;

  //需要在派生类里面重载的方法，必须要写
  protected onWalletReady(): Promise<void> | void {
    this.wallet.addAccountChangeHandler(this.onAccountChange.bind(this));
    if (this.currentAddress) {
      this.Oninit(this.currentAddress);
    }
  }

  //监听到地址变化调用的方法
  private onAccountChange(oldAccount: string, newAcount: string) {
    this.Oninit(newAcount);
  }

  //初始化以及获取当前状态的方法
  public async Oninit(address: string): Promise<void> {
    const status = await Api.getAlphaInfo(address);
    this.status = status.status;
  }

  //关闭小窗口方法
  public close(): void {
    this.isShowSuccess = false;
    this.isShowFaild = false;
  }

  //进行申请的操作
  public async doApllication(): Promise<void> {
    if (!this.currentAddress || this.status != 2) return;

    const signInfo = await Api.getAlphatoSignInfo(this.currentAddress);
    const signature = await this.wallet.signPersonalMessage(
      signInfo.signInfo,
      this.currentAddress
    );
    if (!signature) return;
    const result = await Api.putAlphaTestInfo(this.currentAddress, signature);
    if (result.result != 0) return;
    this.isShowWiting = true;
    this.timer = setInterval(async () => {
      //定时器开始,监听资产情况
      this.listenNum++;
      // console.log(this.listenNum);
      if (this.listenNum >= 5000 || !this.listenStatus) {
        if (result.result == 0 && (await this.listenInAssets()) == 2) {
          this.isShowWiting = false;
          this.isShowSuccess = true;
        } else {
          this.isShowWiting = false;
          this.isShowFaild = true;
        }
        this.Oninit(this.currentAddress);
        if (this.timer) {
          clearInterval(this.timer); // 满足条件时 停止计时
        }
      } else {
        this.listenInAssets();
      }
    }, 10000);
  }
  public async listenInAssets(): Promise<number> {
    const claimResult = await Api.getApplyInfo(this.currentAddress);
    if (claimResult.status != 1) this.listenStatus = false;
    // console.log(claimResult);
    return claimResult.status;
  }
}
