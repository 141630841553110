import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { Contract as EthContract } from "web3-eth-contract";

import Web3MethodReadonly, { Web3MehotdNewer } from "./web3_method_readonly";
import Contract, { ContractMethods } from "../abstract/contract";

class Web3Contract<TMethod extends Web3MethodReadonly>
  implements Contract<TMethod>
{
  public readonly address: string;
  private readonly web3: Web3;
  private readonly MethodNewer: Web3MehotdNewer<TMethod>;
  public methods: ContractMethods<TMethod> = {};
  private contract: EthContract;
  private fGetCaller: () => string;

  public constructor(
    web3: Web3,
    address: string,
    fGetCaller: () => string,
    methodNewer: Web3MehotdNewer<TMethod>
  ) {
    this.web3 = web3;
    this.address = address;
    this.fGetCaller = fGetCaller;
    this.MethodNewer = methodNewer;
    this.contract = new this.web3.eth.Contract([]);
  }

  public get Contract(): EthContract {
    return this.contract;
  }

  public initFromAbis(abis: AbiItem[]): void {
    this.contract = new this.web3.eth.Contract(abis, this.address);
    this.generateMethod();
  }

  public async initFromChain(): Promise<void> {
    throw new Error("Not support");
    // this.contract = new this.web3.eth.Contract()
    // this.generateMethod()
  }

  public generateMethod(): void {
    if (!this.contract) return;

    const names = Object.keys(this.contract.methods);
    for (const name of names) {
      this.methods[name] = (...args: unknown[]) => {
        return new this.MethodNewer(
          this.contract,
          this.fGetCaller,
          name,
          ...args
        );
      };
    }
  }
}

export default Web3Contract;
