


































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import { onNavigate } from "@/logic/define";
import { Api } from "@/logic/api";
import WalletMixin from "@/mixins/wallet";

@Component({
  name: "Header",
  mounted(this: Header) {
    if (location.href.indexOf("register=1") !== -1) {
      this.isShowStartedBox = true;
      this.do_select("register");
    }
  },
})
export default class Header extends Mixins(WalletMixin) {
  // export default class Header extends Vue {
  private showMenu = false;
  isShowChooseBag = false; //是否显示选择钱包弹窗
  isShowAuthorizeFailed = false; //是否显示授权失败弹窗
  isShowResetPwd = false; //是否显示修改密码面板
  isShowCreateAccount = false; //是否显示创建用户面板
  isShowEmailFormatError = false; //提示邮箱格式错误
  isShowPasswordFormatError = false; //提示密码格式错误
  isShowRepeatPasswordFormatError = false; //提示重复密码格式错误
  isShowPasswordTipsTab = false; //输入密码格式提示
  isShowEmialTipsTab = false; //输入邮箱格式
  isShowRepeatPassWordTipsTab = false; //输入重复密码格式提示
  isShowCompleteTab = false; //是否显示提交成功
  isShowResetCompleteTab = false; //是否显示密码重置提交成功
  chooseMatemask = false; //选择matemask
  isShowStartedBox = false; //是否显示选择注册还是开始游戏面板
  isShowCreateFailedTab = false; //是否显示创建失败界面
  failedByCaseOne = false; //创建失败第一种情况 地址已经注册
  failedByCaseTwo = false; //创建失败第二种情况 邮箱已经注册
  failedCauseUnknown = false; //创建失败未知错误
  isShowCodeError = false; //验证码错误
  codeSendSucess = false; //验证码发送是否成功
  isStatusSubmit = false; //是否是可以提交的状态

  new_email = "";
  new_password = "";
  rep_password = "";
  // show_address = "";
  chackCode = "";
  SelectStaging = "";

  res_email = "";
  secondNum = 60;

  protected onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;
  }

  public certification() {
    if (!this.currentAddress) {
      this.isShowChooseBag = false;
      this.chooseMatemask = true;
    } else {
      // console.log("login suceess!!");
      this.isShowChooseBag = false;
      // this.split_address();
      // console.log(this.show_address);
      this.isShowCreateAccount = true;
    }
  }
  //链接钱包之后根据选择的面板进入
  public async connect(): Promise<void> {
    await this.authorize();
    this.chooseMatemask = false;
    if (this.SelectStaging == "register") {
      this.chooseMatemask = false;
      this.isShowCreateAccount = true;
    }
    if (this.SelectStaging == "reset") {
      let signMessage = await Api.getSignMessage();
      let signature = await this.wallet.signPersonalMessage(
        signMessage.signMessage,
        this.currentAddress
      );
      let getaccount = await Api.getAccount(
        this.currentAddress,
        signature,
        signMessage.uuid
      );
      // this.chooseMatemask = false;
      if (!getaccount.account) return;
      this.res_email = getaccount.account;
      this.isShowResetPwd = true;
    }
  }
  public lose_matemask_focus() {
    this.isShowAuthorizeFailed = false;
  }
  //获取验证码流程
  public async getverificationCode(type: number): Promise<void> {
    if (this.new_email == "" && type == 0) return;
    if (this.isShowEmailFormatError) return;
    if (this.codeSendSucess) return;
    this.codeSendSucess = true;
    try {
      if (type) {
        await Api.verificationCode(this.res_email, "reset");
      } else {
        await Api.verificationCode(this.new_email, "create");
      }
    } catch (e) {
      console.log("send error...");
    } finally {
      for (var i = 0; i < 60; i++) {
        await this.delay(1000);
        this.secondNum -= 1;
      }
    }

    this.codeSendSucess = false;
    this.secondNum = 60;
    // console.log(this.new_email);
  }
  //延时逻辑
  private delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  //修改、注册提交逻辑
  public async submit(type: number) {
    if (!this.isStatusSubmit) return;

    if (type) {
      //修改面逻辑
      let signature = "";
      if (
        this.new_password &&
        this.rep_password &&
        this.chackCode &&
        !this.isShowPasswordFormatError &&
        !this.isShowRepeatPasswordFormatError &&
        !this.isShowCodeError
      ) {
        try {
          const address = this.currentAddress;
          const signMessage = await Api.getSignMessage();
          signature = await this.wallet.signPersonalMessage(
            signMessage.signMessage,
            address
          );
          let ResetResult = await Api.resetPasswd(
            this.res_email,
            this.rep_password,
            this.chackCode
          );
          if (ResetResult == "OK") {
            this.cleanInfo();
            this.isShowResetCompleteTab = true;
            this.isShowResetPwd = false;
          } else {
            if (ResetResult.code == 1009) this.isShowCodeError = true;
            else {
              this.failedCauseUnknown = true;
              this.isShowCreateFailedTab = true;
            }
          }
        } catch (e) {
          if (signature !== "") {
            this.failedByCaseOne = false;
            this.failedByCaseTwo = false;
            this.failedCauseUnknown = true;
          }
        }
      }
    } else {
      //注册逻辑
      let signature = "";
      if (
        this.new_email &&
        this.new_password &&
        this.rep_password &&
        this.chackCode &&
        !this.isShowEmailFormatError &&
        !this.isShowPasswordFormatError &&
        !this.isShowRepeatPasswordFormatError &&
        !this.isShowCodeError
      ) {
        try {
          const address = this.currentAddress;
          const signMessage = await Api.getSignMessage();
          signature = await this.wallet.signPersonalMessage(
            signMessage.signMessage,
            address
          );
          //发送创建用户请求
          const res = await Api.createAccount({
            account: this.new_email,
            passwd: this.new_password,
            address,
            signature: signature,
            uuid: signMessage.uuid,
            verificationCode: this.chackCode,
          });
          if (res == "OK") {
            this.isShowCreateAccount = false;
            this.isShowCompleteTab = true;
          } else {
            if (res.code == 1002) {
              this.failedByCaseTwo = true;
              this.isShowCreateFailedTab = true;
            } else if (res.code == 1009) {
              this.isShowCodeError = true;
            } else if (res.code == 1010) {
              this.failedByCaseOne = true;
              this.isShowCreateFailedTab = true;
            } else {
              this.failedCauseUnknown = true;
              this.isShowCreateFailedTab = true;
            }
          }
        } catch (e) {
          console.log("error");
          if (signature !== "") {
            this.isShowCreateFailedTab = true;
            this.failedByCaseOne = false;
            this.failedByCaseTwo = false;
            this.failedCauseUnknown = true;
          }
        }
      } else {
        console.log("Fill in the information format error");
      }
      this.cleanInfo();
    }
  }
  // 在选择注册还是开始游戏界面时点击注册操作
  public async do_select(type: string) {
    this.cleanInfo();
    if (this.currentAddress) {
      // this.split_address();
      if (type == "register") {
        this.isShowStartedBox = false;
        this.isShowResetPwd = false;
        this.isShowCreateAccount = true;
      }
      if (type == "reset") {
        let signMessage = await Api.getSignMessage();
        let signature = await this.wallet.signPersonalMessage(
          signMessage.signMessage,
          this.currentAddress
        );
        let getaccount = await Api.getAccount(
          this.currentAddress,
          signature,
          signMessage.uuid
        );
        if (!getaccount.account) return;
        this.res_email = getaccount.account;
        this.isShowStartedBox = false;
        this.isShowResetPwd = true;
        this.isShowCreateAccount = false;
      }
    } else {
      this.isShowStartedBox = false;
      this.isShowChooseBag = true;
      this.SelectStaging = type;
    }
  }
  //输入失去焦点检查逻辑
  public lose_focus(type: string, ope: string) {
    if (ope == "register") {
      if (type == "email") {
        if (
          /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(
            this.new_email
          )
        )
          this.isShowEmailFormatError = false;
        else this.isShowEmailFormatError = true;

        this.isShowEmialTipsTab = false;
      }
      if (type == "password") {
        if (
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}/.test(
            this.new_password
          )
        ) {
          this.isShowPasswordFormatError = false;
          this.lose_focus("repeatPassword", "register");
        } else this.isShowPasswordFormatError = true;

        this.isShowPasswordTipsTab = false;
      }
      if (type == "repeatPassword") {
        this.isShowRepeatPassWordTipsTab = false;
        if (this.new_password === this.rep_password)
          this.isShowRepeatPasswordFormatError = false;
        else this.isShowRepeatPasswordFormatError = true;
      }
      if (type == "chackCode") {
        if (this.chackCode.length != 6) {
          this.isShowCodeError = true;
        } else {
          this.isShowCodeError = false;
        }
      }
      if (
        this.new_email &&
        this.new_password &&
        this.rep_password &&
        this.chackCode &&
        !this.isShowEmailFormatError &&
        !this.isShowPasswordFormatError &&
        !this.isShowRepeatPasswordFormatError &&
        !this.isShowEmialTipsTab &&
        !this.isShowCodeError
      )
        this.isStatusSubmit = true;
      else this.isStatusSubmit = false;
    } else {
      if (type == "password") {
        if (
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}/.test(
            this.new_password
          )
        ) {
          this.isShowPasswordFormatError = false;
          this.lose_focus("repeatPassword", "reset");
        } else this.isShowPasswordFormatError = true;

        this.isShowPasswordTipsTab = false;
      }
      if (type == "repeatPassword") {
        this.isShowRepeatPassWordTipsTab = false;
        if (this.new_password === this.rep_password)
          this.isShowRepeatPasswordFormatError = false;
        else this.isShowRepeatPasswordFormatError = true;
      }
      if (type == "chackCode") {
        if (this.chackCode.length != 6) {
          this.isShowCodeError = true;
        } else {
          this.isShowCodeError = false;
        }
      }
      if (
        this.new_password &&
        this.rep_password &&
        this.chackCode &&
        !this.isShowPasswordFormatError &&
        !this.isShowRepeatPasswordFormatError &&
        !this.isShowEmialTipsTab &&
        !this.isShowCodeError
      )
        this.isStatusSubmit = true;
      else this.isStatusSubmit = false;
    }
  }
  //输入得到检点检查逻辑
  public get_focus(type: string, ope: string) {
    if (type == "email") {
      this.isShowEmialTipsTab = true;
    }
    if (type == "password") {
      this.isShowPasswordTipsTab = true;
    }
    if (type == "repeatPassword") {
      this.isShowRepeatPassWordTipsTab = true;
    }
  }
  //地址切割逻辑
  public split_address(addre: string): string {
    const len = addre.length;
    return (
      this.currentAddress.substring(0, 10) +
      "..." +
      this.currentAddress.substring(len - 10, len)
    );
  }

  // private addToken() {
  //   const bscSwith = async () => {
  //     const hasSetup = await setupNetwork();
  //     if (hasSetup) {
  //       await registerToken();
  //     }
  //   };
  //   bscSwith();
  // }
  public close(type: string) {
    if (type == "reset") this.isShowResetPwd = false;
    if (type == "create") this.isShowCreateAccount = false;
    this.cleanInfo();
  }
  private cleanInfo() {
    this.new_email = "";
    this.new_password = "";
    this.rep_password = "";
    this.chackCode = "";
    this.isShowEmailFormatError = false;
    this.isShowPasswordFormatError = false;
    this.isShowRepeatPasswordFormatError = false;
    this.isShowCodeError = false;
    this.isStatusSubmit = false;
  }
  public jumpGame() {
    if (process.env.VUE_APP_SERVER_GROUP === "production") {
      window.open("https://game.kingdomx.co/");
    } else if (process.env.VUE_APP_SERVER_GROUP === "prelude") {
      window.open("https://game-prelude.kingdomx.co/");
    } else {
      window.open("https://game-test.kingdomx.co/");
    }
  }

  public jumpMiner() {
    if (process.env.VUE_APP_SERVER_GROUP === "development") {
      window.open("http://localhost:3000/minerwar");
    } else if (process.env.VUE_APP_SERVER_GROUP === "production") {
      window.open("https://dapp.kingdomx.co/minerwar");
    } else {
      window.open("https://dapp-test.kingdomx.co/minerwar");
    }
  }
}
