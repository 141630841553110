/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// type Type = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function'
type SimpleType =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "undefined"
  | "object";
type Validator = (value: any) => boolean;

function ArrayValidator(
  value: any,
  subItemType?: SimpleType | Validator
): boolean {
  if (typeof value !== "object") return false;
  if (!(value instanceof Array)) return false;

  if (subItemType === undefined) {
    return false;
  }

  if (value.length > 0) return true;
  if (typeof subItemType === "string") {
    return typeof value[0] === subItemType;
  }
  return subItemType(value[0]);
}

export function CurrencyValidator(value: any): boolean {
  if (typeof value.name !== "string") return false;
  if (typeof value.symbol !== "string") return false;
  if (typeof value.decimals !== "number") return false;
  return true;
}

export function ChainConfigValidator(value: any): boolean {
  if (typeof value !== "object") return false;
  if (value === null) return false;
  if (typeof value.chainId !== "string") return false;
  if (Number.isNaN(Number.parseInt(value.chainId))) return false;
  if (typeof value.chainName !== "string") return false;
  if (!ArrayValidator(value.rpcUrls, "string")) return false;
  if (!ArrayValidator(value.blockExplorerUrls, "string")) return false;
  if (typeof value.nativeCurrency !== "object") return false;
  if (!CurrencyValidator(value.nativeCurrency)) return false;
  return true;
}

export function AbiInfoValidator(value: any): boolean {
  if (typeof value !== "object") return false;
  if (value === null) return false;
  if (typeof value.address !== "string") return false;
  if (typeof value.abis !== "object") return false;
  if (!(value.abis instanceof Array)) return false;
  return true;
}

export function UniswapPriceValidator(value: any): boolean {
  if (typeof value !== "object") return false;
  if (value === null) return false;

  const isLP = value.IsLP;
  if (typeof isLP !== "boolean") return false;

  if (isLP) {
    if (typeof value.LPToken !== "string") return false;
    if (typeof value.DistToken !== "string") return false;
  } else {
    if (!ArrayValidator(value.TokenPath, "string")) return false;
  }

  return true;
}

export function UniswapValidator(value: any): boolean {
  if (typeof value !== "object") return false;
  if (value === null) return false;

  if (
    value.InTokenPrice === undefined &&
    !UniswapPriceValidator(value.InTokenPrice)
  )
    return false;

  if (
    value.OutTokenPrice === undefined &&
    !UniswapPriceValidator(value.InTokenPrice)
  )
    return false;

  return true;
}

export function MinerPoolValidator(value: any): boolean {
  if (typeof value !== "object") return false;
  if (value === null) return false;
  if (!ChainConfigValidator(value.Chain)) return false;
  if (!AbiInfoValidator(value.Miner)) return false;
  if (!AbiInfoValidator(value.OutToken)) return false;
  if (!AbiInfoValidator(value.InToken)) return false;

  if (value.Uniswap !== undefined && !UniswapValidator(value.Uniswap))
    return false;

  return true;
}
