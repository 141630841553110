











































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageFeatures",
})
export default class PageFeatures extends Vue {}
