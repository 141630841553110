






import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PagePartner",
})
export default class PagePartner extends Vue {}
