import BigNumber from "bignumber.js";
import Vue from "vue";

let opts = {
  tokenUnit: 18,
  tokenDecimal: 4,
  omitPlace: 7,
  ratioWithPercent: true,
  cutDownExpire: "expire",
  isTimestampMs: false,
};

export function setOpts(o: Partial<typeof opts>): void {
  opts = {
    ...opts,
    ...o,
  };
}

Vue.filter(
  "wei2string",
  function (
    num: BigNumber.Value,
    unit = opts.tokenUnit,
    decimal = opts.tokenDecimal
  ) {
    return new BigNumber(num).integerValue().div(`1e${unit}`).toFormat(decimal);
  }
);

Vue.filter(
  "omitCenter",
  function (str: string, head = opts.omitPlace, tail = opts.omitPlace) {
    if (str.length <= head + tail) {
      return str;
    }
    return `${str.substr(0, head)}...${str.substr(-tail)}`;
  }
);

Vue.filter("ratio2string", function (num: number) {
  const head = Math.trunc(num / 100);
  const tail = Math.trunc(num % 100);
  return `${head}.${tail < 10 ? "0" + tail : tail}%`;
});

Vue.filter(
  "ratio",
  function (num: number, withPercent = opts.ratioWithPercent) {
    num *= 10000;
    const head = Math.trunc(num / 100);
    const tail = Math.trunc(num % 100);
    return `${head}.${tail < 10 ? "0" + tail : tail}${withPercent ? "%" : ""}`;
  }
);

Vue.filter("cutdown", function (num: number, overflow = opts.cutDownExpire) {
  if (num <= 0) {
    return overflow;
  } else {
    const s = num % 60;
    num = Math.trunc(num / 60);
    const m = num % 60;
    const h = Math.trunc(num / 60);
    return `${h}:${m}:${s}`;
  }
});
