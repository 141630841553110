import { ChainConfig } from "@/logic/block_chain/abstract/define";
import Wallet, { WalletOpts } from "./abstract/wallet";
import Web3Wallet from "./web3/web3_wallet";

type WalletNewer = new (opt: WalletOpts) => Wallet;

class WalletManagerClass {
  private readonly mapWallet = new Map<number, Wallet>();
  private readonly defaultWalletNewer: WalletNewer = Web3Wallet;
  private readonly mapWalletNewer = new Map<number, WalletNewer>([]);

  getWallet(chainCfg: ChainConfig): Wallet {
    const wallet = this.mapWallet.get(Number.parseInt(chainCfg.chainId));
    if (wallet) return wallet;

    const walletNewer = this.getWalletNewer(chainCfg);
    // eslint-disable-next-line new-cap
    return new walletNewer({
      nodeUrl: chainCfg.rpcUrls[0],
      chainId: Number.parseInt(chainCfg.chainId),
      contracts: {},
    });
  }

  private getWalletNewer(chainCfg: ChainConfig): WalletNewer {
    const walletNewer = this.mapWalletNewer.get(
      Number.parseInt(chainCfg.chainId)
    );
    if (walletNewer) return walletNewer;

    return this.defaultWalletNewer;
  }
}

const WalletManager = new WalletManagerClass();
export default WalletManager;
