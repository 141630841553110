var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"alpha_body_pc"}},[_c('img',{attrs:{"src":require("../assets/alpha/pc/alpha_title.png"),"alt":""}}),_c('div',{staticClass:"status"},[_vm._v(" Status: "+_vm._s(_vm.status != 0 ? _vm.status == 1 ? "Permission Denied" : _vm.status == 2 ? "No Application" : "Application Already" : "——")+" ")]),_vm._m(0),_c('div',{staticClass:"button_faram"},[(!_vm.currentAddress)?_c('div',{staticClass:"able",on:{"click":_vm.authorize}},[_vm._v(" Approve ")]):_vm._e(),(_vm.currentAddress && _vm.status == 2)?_c('div',{staticClass:"able",on:{"click":function($event){return _vm.doApllication()}}},[_vm._v(" Apply ")]):_vm._e(),(
          (_vm.currentAddress && _vm.status == 1) ||
          (_vm.currentAddress && _vm.status == 3) ||
          (_vm.currentAddress && _vm.status == 0)
        )?_c('div',{staticClass:"unable"},[_vm._v(" Apply ")]):_vm._e()]),_c('div',{staticClass:"button_tip"},[_vm._v(" Each Wallet can only apply test assets once Please consider carefully before the application ")]),_c('div',{staticClass:"apply_tip"},[_vm._v(" If you own Heroes ≥5, you will get the corresponding test Heroes, otherwise, you will get 5 common test Heroes.If you have KT/KNT≥500, you will get the corresponding amount of test KT/KNT, and upper limit is 50000 test KT/KNT. Otherwise, you will get 500 test KT/KNT. ")]),(_vm.isShowWiting)?_c('div',{staticClass:"board witing"},[_vm._m(1)]):_vm._e(),(_vm.isShowSuccess || _vm.isShowFaild)?_c('div',{staticClass:"board reminder"},[_c('div',[_vm._v("Reminder")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowSuccess),expression:"isShowSuccess"}]},[_vm._v(" You have successfully applied for test assets. Please switch your wallet to BNB Smart Chain Testnet to check. ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFaild),expression:"isShowFaild"}]},[_vm._v("Application failed, please try again.")]),_c('div',{on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])]):_vm._e()]),_c('div',{attrs:{"id":"alpha_body_mobile"}},[_c('div',{staticClass:"center_title"}),_c('div',{staticClass:"User_status"},[_vm._v(" Status: "+_vm._s(_vm.status != 0 ? _vm.status == 1 ? " Permission Denied" : _vm.status == 2 ? " No Application" : " Application Already" : " ——")+" ")]),_vm._m(2),_c('div',{staticClass:"button_faram"},[(!_vm.currentAddress)?_c('div',{staticClass:"able",on:{"click":_vm.authorize}},[_vm._v(" Approve ")]):_vm._e(),(_vm.currentAddress && _vm.status == 2)?_c('div',{staticClass:"able",on:{"click":function($event){return _vm.doApllication()}}},[_vm._v(" Apply ")]):_vm._e(),(
          (_vm.currentAddress && _vm.status == 1) ||
          (_vm.currentAddress && _vm.status == 3) ||
          (_vm.currentAddress && _vm.status == 0)
        )?_c('div',{staticClass:"unable"},[_vm._v(" Apply ")]):_vm._e()]),_c('div',{staticClass:"button_tip"},[_vm._v(" Each Wallet can only apply test assets once Please consider carefully before the application ")]),_vm._m(3),(_vm.isShowWiting)?_c('div',{staticClass:"board witing"},[_c('div',[_vm._v("Reminder")]),_c('div',[_vm._v("Waiting for processing...")])]):_vm._e(),(_vm.isShowSuccess || _vm.isShowFaild)?_c('div',{staticClass:"board reminder"},[_c('div',[_vm._v("Reminder")]),(_vm.isShowSuccess)?_c('div',[_vm._v(" You have successfully applied for test assets. Please switch your wallet to BNB Smart Chain Testnet to check. ")]):_vm._e(),(_vm.isShowFaild)?_c('div',[_vm._v("Application failed, please try again.")]):_vm._e(),_c('div',{staticClass:"close",on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])]):_vm._e()]),(_vm.isShowWiting || _vm.isShowSuccess || _vm.isShowFaild)?_c('div',{staticClass:"black-mask"}):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tips"},[_c('p',[_vm._v("The distribution amount of test assets depends on the current")]),_c('p',[_vm._v(" amount of assets in your wallet. Test asset application is limited to whitelist ")]),_c('p',[_vm._v(" users. "),_c('a',{attrs:{"href":"https://t.me/KingdomXGame"}},[_vm._v("Click here")]),_vm._v(" to join community campaigns winning whitelists. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("Waiting for processing...")]),_c('p',{staticClass:"wait"},[_vm._v("This will take a while, please be patient.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tip_content"},[_c('p',[_vm._v("The distribution amount of test")]),_c('p',[_vm._v("assets depends on the current amountof")]),_c('p',[_vm._v("assets in your wallet. Test asset application is")]),_c('p',[_vm._v(" limited to whitelist users. "),_c('a',{attrs:{"href":"https://t.me/KingdomXGame"}},[_vm._v("Click here")])]),_c('p',[_vm._v("join community campaigns for winning")]),_vm._v(" whitelists. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"apply_tip"},[_c('p',[_vm._v("If you own Heroes ≥5, you will get the")]),_c('p',[_vm._v("corresponding test Heroes, otherwise, you will")]),_c('p',[_vm._v("get 5 common test Heroes.If you have")]),_c('p',[_vm._v("KT/KNT≥500, you will get the corresponding amount")]),_c('p',[_vm._v("of test KT/KNT, and upper limit is 50000 test")]),_c('p',[_vm._v("KT/KNT. Otherwise, you will get 500 test KT/KNT.")])])}]

export { render, staticRenderFns }