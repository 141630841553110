// import { Type } from "class-transformer";
import {
  IsBoolean,
  IsEnum,
  IsEthereumAddress,
  IsHexadecimal,
  IsInt,
  IsNumberString,
  IsUUID,
  Max,
  Min,
} from "class-validator";

export enum SellActivityType {
  Sell,
  Exchange,
}

export class DtoSellKey {
  // @Type(() => Number)
  @IsInt()
  @Min(1)
  sellid!: number;

  @IsEthereumAddress()
  address!: string;
}

export class DtoActivityInfo {
  @IsInt()
  @Min(1)
  id!: number;

  @IsEnum(SellActivityType)
  type!: SellActivityType;

  @IsEthereumAddress()
  sellContract!: string;

  @IsEthereumAddress()
  inContract!: string;

  @IsEthereumAddress()
  outContract!: string;

  @IsInt()
  startTime!: number;

  @IsInt()
  endTime!: number;
}

export class DtoSellActivityList {
  list!: DtoActivityInfo[];
}

export class DtoSellInWhiteList {
  @IsBoolean()
  IsInWhiteList!: boolean;
}

export class DtoGetVerifyMessageReq {
  @IsInt()
  @Min(1)
  activityid!: number;

  @IsEthereumAddress()
  address!: string;
}

export class DtoGetVerifyMessageRsp {
  @IsUUID(4)
  message!: string;
}

export class DtoGetSellTransacitonReq {
  @IsInt()
  @Min(1)
  activityid!: number;

  @IsEthereumAddress()
  address!: string;

  @IsHexadecimal()
  signature!: string;
}

export class DtoGetSellTransacitonRsp {
  @IsEthereumAddress()
  caller!: string;

  @IsEthereumAddress()
  target!: string;

  @IsHexadecimal()
  functionHash!: string;

  @IsInt()
  deadline!: number;

  @IsHexadecimal()
  signature!: string;

  @IsHexadecimal()
  v!: string;

  @IsHexadecimal()
  r!: string;

  @IsHexadecimal()
  s!: string;
}

export class DtoGetExchangeTransacitonReq {
  @IsInt()
  @Min(1)
  activityid!: number;

  @IsEthereumAddress()
  address!: string;

  @IsNumberString()
  tokenId!: string;
}

export class DtoGetExchangeTransacitonRsp {
  @IsEthereumAddress()
  caller!: string;

  @IsNumberString()
  tokenId!: string;

  @IsEthereumAddress()
  target!: string;

  @IsHexadecimal()
  functionHash!: string;

  @IsInt()
  deadline!: number;

  @IsHexadecimal()
  signature!: string;

  @IsInt()
  @Min(0)
  @Max(255)
  v!: number;

  @IsHexadecimal()
  r!: string;

  @IsHexadecimal()
  s!: string;
}
