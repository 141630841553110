






























import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageToken",
})
export default class PageToken extends Vue {}
