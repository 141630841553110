import BigNumber from "bignumber.js";
import {
  Contract as EthContract,
  EventData as EthEventData,
} from "web3-eth-contract";

import { MethodSendOpt } from "../abstract/define";
import MethodReadonly from "../abstract/method_readonly";

class Web3MethodReadonly implements MethodReadonly {
  protected ethContract: EthContract;
  protected callerGetter: () => string;
  protected name: string;
  protected args: unknown[];
  // protected gasPric = new BigNumber(20).times(1_000_000_000)
  protected gasPric = new BigNumber(20).times(1000000000);

  public constructor(
    ethContract: EthContract,
    callerGetter: () => string,
    name: string,
    ...args: unknown[]
  ) {
    this.ethContract = ethContract;
    this.callerGetter = callerGetter;
    this.name = name;
    this.args = args;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async call(): Promise<any> {
    try {
      return await this.ethContract.methods[this.name](...this.args).call({
        from: this.callerGetter(),
      });
    } catch (e) {
      const error = e as Error;
      error.message =
        `Failed to send contract method ${this.name}. contract: ${
          this.ethContract.options.address
        } Args: [${this.args.join(", ")}].\n` + error.message;
      throw error;
    }
  }

  public async estimateGas(opt?: MethodSendOpt): Promise<BigNumber> {
    try {
      return new BigNumber(
        await this.ethContract.methods[this.name](...this.args).estimateGas({
          from: this.callerGetter(),
          value: opt?.transferValue?.toString(),
        })
      );
    } catch (e) {
      const error = e as Error;
      error.message =
        `Failed to send contract method ${this.name}. contract: ${
          this.ethContract.options.address
        } Args: [${this.args.join(", ")}].\n` + error.message;
      throw error;
    }
  }
}

export type Web3MehotdNewer<TMethod extends Web3MethodReadonly> = new (
  ethContract: EthContract,
  fGetCaller: () => string,
  name: string,
  ...args: unknown[]
) => TMethod;
export default Web3MethodReadonly;
