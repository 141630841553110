
















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PagePading",
  props: ["pageSize", "infoNum", "nowBoardPage"],
})
export default class PageAllocation extends Vue {
  nowPage: any;
  nowBoardPage: any;
  pagecount = 0;
  pageSize: any;
  infoNum: any;
  pageList: any[] = [];
  showpageNum = 7;

  mounted() {
    this.nowPage = this.nowBoardPage;
    let maxPage = Math.ceil(this.infoNum / this.pageSize);
    this.pagecount = maxPage;
    var list: string[] = [];
    if (maxPage <= this.showpageNum) {
      for (var i = 0; i < maxPage; i++) {
        list[i] = i + 1 + "";
      }
    } else {
      for (var n = 0; n < this.showpageNum - 2; n++) {
        list[n] = n + 1 + "";
      }
      list[this.showpageNum - 2] = "...";
      list[this.showpageNum - 1] = maxPage + "";
    }
    this.pageList = list;
    this.$emit("change", this.nowPage);
  }

  public changepage(type: number): void {
    if (type) {
      if (this.pagecount > this.nowPage) {
        this.nowPage += 1;
      }
    } else if (this.nowPage > 1) {
      this.nowPage -= 1;
    }
    this.update();
    this.$emit("change", this.nowPage);
  }

  public topage(pagenum: number): void {
    if (String(pagenum) == "...") {
      return;
    }
    if (pagenum != this.nowPage) {
      this.nowPage = Number(pagenum);
    }

    this.update();
    this.$emit("change", this.nowPage);
  }

  public update() {
    if (this.pagecount <= this.showpageNum) return;
    var list: string[] = [];
    if (this.nowPage < this.showpageNum - 1) {
      for (var n = 0; n < this.showpageNum - 2; n++) {
        list[n] = n + 1 + "";
      }
      list[this.showpageNum - 2] = "...";
      list[this.showpageNum - 1] = this.pagecount + "";
    } else if (
      this.nowPage >= this.showpageNum - 1 &&
      this.nowPage < this.pagecount - 4
    ) {
      list[0] = "1";
      list[1] = "...";
      list[2] = this.nowPage - 1 + "";
      list[3] = this.nowPage + "";
      list[4] = this.nowPage + 1 + "";
      list[5] = "...";
      list[6] = this.pagecount + "";
    } else {
      list[0] = "1";
      list[1] = "...";
      list[2] = this.pagecount - 4 + "";
      list[3] = this.pagecount - 3 + "";
      list[4] = this.pagecount - 2 + "";
      list[5] = this.pagecount - 1 + "";
      list[6] = this.pagecount + "";
    }
    this.pageList = list;
  }
}
