










































































































































































































import { Component, Mixins } from "vue-property-decorator";

import { onNavigate } from "@/logic/define";
import WalletMixin from "@/mixins/wallet";
import { setupNetwork, registerToken } from "./wallets";

@Component({
  name: "Header",
})
export default class Header extends Mixins(WalletMixin) {
  // export default class Header extends Vue {
  private showMenu = false;
  isShowChooseBag = false; //是否显示选择钱包弹窗
  isShowAuthorizeFailed = false; //是否显示授权失败弹窗
  isShowCreateAccount = false; //是否显示创建用户面板
  isShowEmailFormatError = false; //提示邮箱格式错误
  isShowPasswordFormatError = false; //提示密码格式错误
  isShowRepeatPasswordFormatError = false; //提示重复密码格式错误
  isShowPasswordTipsTab = false; //输入密码格式提示
  isShowRepeatPassWordTipsTab = false; //输入重复密码格式提示
  isShowCompleteTab = false; //是否显示提交成功
  chooseMatemask = false; //选择matemask
  isShowStartedBox = false; //是否显示选择注册还是开始游戏面板

  new_email = "";
  new_password = "";
  rep_password = "";

  protected onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;
  }

  private certification(): void {
    if (!this.currentAddress) {
      this.chooseMatemask = true;
      this.isShowChooseBag = false;
      this.isShowCreateAccount = true;
    } else {
      console.log("login suceess!");
      this.isShowChooseBag = false;
      this.isShowCreateAccount = true;
    }
  }

  private onBtnClick(index: number) {
    onNavigate.call(this, index);
  }

  private openLink(url: string, noNewTab = false) {
    window.open(url, noNewTab ? "_self" : "_blank");
  }

  private addToken() {
    const bscSwith = async () => {
      const hasSetup = await setupNetwork();
      if (hasSetup) {
        await registerToken();
      }
    };
    bscSwith();
  }
}
