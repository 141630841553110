








import { Component, Vue, Mixins } from "vue-property-decorator";
import WalletMixin from "@/mixins/wallet";

export default class Header extends Vue {}
