





























































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageList",
})
export default class HomePage extends Vue {
  activeBoxIndex = 0;

  pcBoxMouseOver(boxIndex: number): void {
    this.activeBoxIndex = boxIndex;
  }

  private openLink(url: string) {
    window.open(url, "_blank");
  }

  mobileOpenLink(boxIndex: number, url: string): void {
    this.activeBoxIndex = boxIndex;
    window.open(url, "_blank");
  }
}
