import { Component, Mixins } from "vue-property-decorator";
import BigNumber from "bignumber.js";

import { MinerPoolValidator } from "@/logic/contract-config/validator";
import {
  MinerPool,
  UniswapPrice,
  UniswapPriceLP,
} from "@/logic/contract-config/define";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import Method from "@/logic/block_chain/abstract/method";
import Contract from "@/logic/block_chain/abstract/contract";
import WalletManager from "@/logic/block_chain/wallet-manager";

import ContractCfg from "@/logic/contract-config";
import { ERC20Abi } from "@/logic/contract-config/abi";
import WalletMixin from "./wallet";

const uniswapWallet = WalletManager.getWallet(ContractCfg.UniswapRouter.Chain);
const uniswapRouterContract = uniswapWallet.ReadonlyBlockChain.createContract(
  ContractCfg.UniswapRouter.Contract.address,
  ContractCfg.UniswapRouter.Contract.abis
);

@Component({
  props: {
    name: {
      type: String,
      required: true,
    },
    PoolInfo: {
      type: Object,
      required: true,
      validator: MinerPoolValidator,
    },
  },
})
export default class MinerMixin extends Mixins(WalletMixin) {
  // props
  name!: string;
  PoolInfo!: MinerPool;

  // wallet
  minerContractR!: Contract<MethodReadonly>;
  inTokenContractR!: Contract<MethodReadonly>;
  outTokenContractR!: Contract<MethodReadonly>;
  minerContract!: Contract<Method>;
  inTokenContract!: Contract<Method>;
  outTokenContract!: Contract<Method>;
  inTokenDecimal = 18;
  outTokenDecimal = 18;

  currentAddress = "";
  currentChainId = "";

  public created(): void {
    this.minerContractR = this.wallet.ReadonlyBlockChain.createContract(
      this.PoolInfo.Miner.address,
      this.PoolInfo.Miner.abis
    );

    this.inTokenContractR = this.wallet.ReadonlyBlockChain.createContract(
      this.PoolInfo.InToken.address,
      this.PoolInfo.InToken.abis
    );

    this.outTokenContractR = this.wallet.ReadonlyBlockChain.createContract(
      this.PoolInfo.OutToken.address,
      this.PoolInfo.OutToken.abis
    );

    this.initGlobalInfo();
  }

  protected async initGlobalInfo(): Promise<void> {
    [this.inTokenDecimal, this.outTokenDecimal] = await Promise.all([
      this.methodCallToInt(this.inTokenContractR.methods.decimals()),
      this.methodCallToInt(this.outTokenContractR.methods.decimals()),
    ]);
  }

  protected onWalletReady(): void {
    if (!this.wallet.WritableBlockChain) return;

    this.minerContract = this.wallet.WritableBlockChain.createContract(
      this.PoolInfo.Miner.address,
      this.PoolInfo.Miner.abis
    );

    this.inTokenContract = this.wallet.WritableBlockChain.createContract(
      this.PoolInfo.InToken.address,
      this.PoolInfo.InToken.abis
    );

    this.outTokenContract = this.wallet.WritableBlockChain.createContract(
      this.PoolInfo.OutToken.address,
      this.PoolInfo.OutToken.abis
    );
  }

  // readonly interface
  protected async allowance(account: string): Promise<BigNumber> {
    return this.methodCallToBN(
      this.inTokenContractR.methods.allowance(
        account,
        this.minerContract.address
      )
    );
  }

  protected async getTotalSupply(): Promise<BigNumber> {
    return this.methodCallToBN(this.minerContractR.methods.totalSupply());
  }

  protected async getYearTokenRate(): Promise<BigNumber> {
    return this.methodCallToBN(this.minerContractR.methods.yearTokenRate());
  }

  protected async getRewardRate(): Promise<BigNumber> {
    return this.methodCallToBN(this.minerContractR.methods.rewardRate());
  }

  protected async getStakedToken(account: string): Promise<BigNumber> {
    return this.balanceOf(account, this.minerContractR);
  }

  protected async getUserReward(account: string): Promise<BigNumber> {
    return this.methodCallToBN(this.minerContractR.methods.earned(account));
  }

  protected async getApy(apr?: BigNumber): Promise<BigNumber> {
    if (!apr) {
      apr = await this.getApr();
    }

    return this.getApyByApr(apr);
  }

  protected getApyByApr(apr: BigNumber): BigNumber {
    return apr.div(365).plus(1).pow(365).minus(1);
  }

  protected async getApr(): Promise<BigNumber> {
    const condition = false;
    if (condition) {
      const [yearTokenRate, inTokenPrice, outTokenPrice] = await Promise.all([
        this.getYearTokenRate(),
        this.getInTokenPrice(),
        this.getOutTokenPrice(),
      ]);

      // console.log(
      //   `inTokenPrice ${inTokenPrice} outTokenPrice ${outTokenPrice}`
      // );

      if (yearTokenRate.lte(0)) {
        return new BigNumber(0);
      }

      return yearTokenRate.times(outTokenPrice).div(inTokenPrice);
    } else {
      // stakePrice = UniswapRouterV2.getAmountsOut(1e18, rewardPath?)
      // rewardPrice = UniswapRouterV2.getAmountsOut(1e18, rewardPath?)
      // totalStakeValue = totalSupply * stakePrice
      // totalRewardValue = miner.rewardRate() * 86400 * 365 * rewardPrice
      // apy = totalRewardValue * 100 / totalStakeValue
      const [rewardRate, totalSupply, inTokenPrice, outTokenPrice] =
        await Promise.all([
          this.methodCallToBN(this.minerContractR.methods.rewardRate()),
          this.getTotalSupply(),
          this.getInTokenPrice(),
          this.getOutTokenPrice(),
        ]);

      // console.log(
      //   `Pool ${this.name} inTokenPrice ${inTokenPrice} outTokenPrice ${outTokenPrice}`
      // );

      if (rewardRate.lte(0) || totalSupply.lte(0)) {
        return new BigNumber(0);
      }

      // console.log(
      //   `rewardRate ${rewardRate} totalSupply ${totalSupply} inTokenPrice ${inTokenPrice} outTokenPrice ${outTokenPrice}`
      // );
      const totalInValue = totalSupply.times(inTokenPrice).integerValue();
      const totalOutValue = rewardRate
        .times(86400)
        .times(365)
        .times(outTokenPrice)
        .integerValue();
      // console.log(`totalInValue ${totalInValue} totalOutValue ${totalOutValue} `);
      // console.log(
      //   `yearTokenRate ${await this.methodCallToBN(
      //     this.minerContract.methods.yearTokenRate()
      //   )}`
      // );
      return totalOutValue.div(totalInValue);
    }
  }

  protected async getInTokenPrice(): Promise<BigNumber> {
    const outTokenBaseAmount = new BigNumber(`1e${this.inTokenDecimal}`);
    if (this.PoolInfo.Uniswap && this.PoolInfo.Uniswap.InTokenPrice) {
      return this.getTokenPrice(
        outTokenBaseAmount,
        this.PoolInfo.Uniswap.InTokenPrice
      );
    } else {
      return outTokenBaseAmount;
    }
  }

  protected async getOutTokenPrice(): Promise<BigNumber> {
    const outTokenBaseAmount = new BigNumber(`1e${this.outTokenDecimal}`);
    if (this.PoolInfo.Uniswap && this.PoolInfo.Uniswap.OutTokenPrice) {
      return this.getTokenPrice(
        outTokenBaseAmount,
        this.PoolInfo.Uniswap.OutTokenPrice
      );
    } else {
      return outTokenBaseAmount;
    }
  }

  private async getTokenPrice(
    outTokenBaseAmount: BigNumber,
    tokenPrice: UniswapPrice
  ) {
    if (tokenPrice.IsLP) {
      return this.getLPTokenPrice(outTokenBaseAmount, tokenPrice);
    } else {
      return this.getTokenPriceByPath(outTokenBaseAmount, tokenPrice.TokenPath);
    }
  }

  private async getLPTokenPrice(
    baseAmount: BigNumber,
    tokenPrice: UniswapPriceLP
  ): Promise<BigNumber> {
    const blockChain = uniswapWallet.ReadonlyBlockChain;
    const contracts = {
      lp: blockChain.createContract(tokenPrice.LPToken, ERC20Abi),
      token: blockChain.createContract(tokenPrice.DistToken, ERC20Abi),
    };

    const [distToken, totalSupply] = await Promise.all([
      this.methodCallToBN(
        contracts.token.methods.balanceOf(tokenPrice.LPToken)
      ),
      this.methodCallToBN(contracts.lp.methods.totalSupply()),
    ]);

    return distToken.times(2).times(baseAmount).div(totalSupply).integerValue();
  }

  private async getTokenPriceByPath(
    baseAmount: BigNumber,
    tokenPath: string[]
  ): Promise<BigNumber> {
    const result = (await uniswapRouterContract.methods
      .getAmountsOut(baseAmount.toString(10), tokenPath)
      .call()) as string[];
    return new BigNumber(result[1]);
  }

  // writable interface
  protected async approve(): Promise<void> {
    if (!this.wallet.IsWalletReady) {
      await this.authorize();
    }

    if (!this.inTokenContract)
      throw new Error("Can not approve with native token.");
    const txId = await this.inTokenContract.methods
      .approve(this.minerContract.address, "10000000000000000000000000")
      .send({});
    this.onTransactionOver(txId);
  }

  protected async stake(amount: BigNumber): Promise<void> {
    const txId = await this.minerContract.methods
      .stake(amount.toString(10))
      .send({});
    this.onTransactionOver(txId);
  }

  protected async withdraw(amount: BigNumber): Promise<void> {
    const txId = await this.minerContract.methods
      .withdraw(amount.toString(10))
      .send({});
    this.onTransactionOver(txId);
  }

  // 提取本金和领取奖励
  protected async exit(): Promise<void> {
    const txId = await this.minerContract.methods.exit().send({});
    this.onTransactionOver(txId);
  }

  // 领取奖励
  protected async getReward(): Promise<void> {
    const txId = await this.minerContract.methods.getReward().send({});
    this.onTransactionOver(txId);
  }

  protected onTransactionOver(txId: string): void {
    console.log("TxId: ", txId);
  }
}
