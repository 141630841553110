// import * as THREE from "three";
// import * as spine from "@esotericsoftware/spine-threejs";

interface Position {
  x: number;
  y: number;
}

interface SpineOpt {
  canvas: HTMLCanvasElement;
  skeletonFile: string;
  atlasFile?: string;
  pathPrefix?: string;
  animation: string;
  spienScale?: number;
  spinePosition?: Position;
  onLoaded?: () => void;
  debug?: boolean;
}

const MinScaleRatio = 10;
const ScaleRatio = 100;

export default class Spine {
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.Renderer;
  private assetManager: spine.threejs.AssetManager;
  private skeletonMesh?: spine.threejs.SkeletonMesh;

  readonly canvas: HTMLCanvasElement;
  private skeletonFile: string;
  private atlasFile: string;
  private pathPrefix: string;
  private animaName?: string;
  private spineScale: number;
  private spinePosition: Position;
  private onLoaded?: () => void;

  private debug: boolean;
  private isMouseDown = false;
  private mouseDownStartPos: Position = { x: 0, y: 0 };
  private spineStartPos: Position = { x: 0, y: 0 };
  private spineCurScale = ScaleRatio;

  private isLoaded = false;
  private isRendering = false;
  private lastFrameTime = 0;

  public set IsRendering(v: boolean) {
    this.isRendering = v;
  }

  public get IsRendering(): boolean {
    return this.isRendering;
  }

  constructor(opt: SpineOpt) {
    this.canvas = opt.canvas;
    this.skeletonFile = opt.skeletonFile;
    this.atlasFile = opt.atlasFile || this.getAtlasFile(opt.skeletonFile);
    this.pathPrefix = opt.pathPrefix || "assets/";
    this.animaName = opt.animation;
    this.spineScale = opt.spienScale || 1;
    this.spinePosition = opt.spinePosition || { x: 0, y: 0 };
    this.onLoaded = opt.onLoaded;
    this.debug = opt.debug || false;

    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.scene = new THREE.Scene();
    const aspect = this.canvas.width / this.canvas.height;
    this.camera = new THREE.PerspectiveCamera(50, aspect, 0.1, 2000);
    this.camera.position.z = 400;
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
    });
    this.renderer.setSize(this.canvas.width, this.canvas.height);
    this.assetManager = new spine.threejs.AssetManager(this.pathPrefix);
  }

  startLoad(): void {
    this.assetManager.loadText(this.skeletonFile);
    this.assetManager.loadTextureAtlas(this.atlasFile);

    this.debug && this.startDebug();

    requestAnimationFrame(this.update.bind(this));
  }

  private startDebug() {
    this.canvas.addEventListener("mousedown", (e) => {
      e.stopPropagation();

      this.isMouseDown = true;
      this.mouseDownStartPos.x = e.offsetX;
      this.mouseDownStartPos.y = e.offsetY;
      this.spineStartPos.x = this.skeletonMesh?.position.x || 0;
      this.spineStartPos.y = this.skeletonMesh?.position.y || 0;
    });

    this.canvas.addEventListener("mousemove", (e) => {
      if (!this.isMouseDown) return;

      e.stopPropagation();

      if (!this.skeletonMesh) return;

      const deltaX = e.offsetX - this.mouseDownStartPos.x;
      const deltaY = -(e.offsetY - this.mouseDownStartPos.y);

      this.skeletonMesh.position.x = (this.spineStartPos.x + deltaX) * 10;
      this.skeletonMesh.position.y = (this.spineStartPos.y + deltaY) * 10;

      console.log(
        "position: ",
        this.skeletonMesh.position.x,
        this.skeletonMesh.position.y
      );
    });

    this.canvas.addEventListener("mouseup", () => (this.isMouseDown = false));

    this.canvas.addEventListener("wheel", (e) => {
      e.stopPropagation();

      if (!this.skeletonMesh) return;

      const deltaScale = (e.deltaY > 0 ? -1 : 1) * MinScaleRatio;
      if (this.spineCurScale + deltaScale > 0) this.spineCurScale += deltaScale;

      const scale = this.skeletonMesh.scale;
      const realScale = this.spineCurScale / ScaleRatio;
      scale.x = realScale;
      scale.y = realScale;
      scale.z = realScale;

      console.log("scale: ", realScale);
    });
  }

  private update() {
    if (!this.isLoaded) {
      if (this.assetManager.isLoadingComplete()) {
        this.isLoaded = true;
        this.onLoadComplete();
        if (this.onLoaded) this.onLoaded();
      }
    } else {
      this.resize();
      if (this.isRendering) {
        this.render();
      }
    }
    requestAnimationFrame(this.update.bind(this));
  }

  private onLoadComplete() {
    // Load the texture atlas using name.atlas and name.png from the AssetManager.
    // The function passed to TextureAtlas is used to resolve relative paths.
    const atlas = this.assetManager.get(this.atlasFile);

    // Create a AtlasAttachmentLoader that resolves region, mesh, boundingbox and path attachments
    const atlasLoader = new spine.AtlasAttachmentLoader(atlas);

    // Create a SkeletonJson instance for parsing the .json file.
    const skeletonJson = new spine.SkeletonJson(atlasLoader);

    // Set the scale to apply during parsing, parse the file, and create a new skeleton.
    skeletonJson.scale = this.spineScale;
    const skeletonData = skeletonJson.readSkeletonData(
      this.assetManager.get(this.skeletonFile)
    );

    // Create a SkeletonMesh from the data and attach it to the scene
    this.skeletonMesh = new spine.threejs.SkeletonMesh(skeletonData);
    this.animaName &&
      this.skeletonMesh.state.setAnimation(0, this.animaName, true);
    this.skeletonMesh.position.x = this.spinePosition.x;
    this.skeletonMesh.position.y = this.spinePosition.y;
    this.skeletonMesh.scale.x = 1.01;
    this.skeletonMesh.scale.y = 1.01;
    this.skeletonMesh.scale.z = 1.01;
    this.scene.add(this.skeletonMesh);

    this.lastFrameTime = Date.now() / 1000;
  }

  private render() {
    const now = Date.now() / 1000;
    const delta = now - this.lastFrameTime;
    this.lastFrameTime = now;

    // update the animation
    this.skeletonMesh?.update(delta);

    // render the scene
    this.renderer.render(this.scene, this.camera);
  }

  private resize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    if (this.canvas.width === w && this.canvas.height === h) {
      return;
    }

    this.canvas.width = w;
    this.canvas.height = h;

    this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(w, h);
  }

  private getAtlasFile(skeletonFile: string) {
    return skeletonFile
      .replace("-pro", "")
      .replace("-ess", "")
      .replace(".json", ".atlas");
  }
}
