


































































































import { Component, Mixins } from "vue-property-decorator";
import BigNumber from "bignumber.js";

import MinerMixin from "@/mixins/miner";
import * as util from "@/logic/core/util";
import FullMask from "@/components/FullMask.vue";

enum Tabs {
  Stake,
  Claim,
}

@Component({
  name: "MinerOperator",
  components: {
    FullMask,
  },
})
export default class MinerOperator extends Mixins(MinerMixin) {
  Tabs = Tabs;

  walletBalance = new BigNumber(0);
  stakedToken = new BigNumber(0);
  canWithdraw = new BigNumber(0);
  myReward = new BigNumber(0);
  APR = 0;
  APY = 0;

  tabIndex = Tabs.Stake;
  inputStake = 0;

  tick = true;

  created(): void {
    this.startTick();
  }

  protected async startTick(): Promise<void> {
    while (this.tick) {
      await Promise.all([this.refreshGlobalInfo(), this.refreshPersonalInfo()]);
      await util.waitForMs(3000);
    }
  }

  protected async refreshPersonalInfo(): Promise<void> {
    if (!this.currentAddress) return;

    [this.walletBalance, this.stakedToken, this.myReward] = await Promise.all([
      this.balanceOf(this.currentAddress, this.inTokenContractR),
      this.getStakedToken(this.currentAddress),
      this.getUserReward(this.currentAddress),
    ]);
  }

  protected async refreshGlobalInfo(): Promise<void> {
    const apr = await this.getApr();

    if (apr.gt(20)) {
      this.APR = this.bnToRatio(apr);
      this.APY = 0;
    } else {
      this.APR = this.bnToRatio(apr);
      this.APY = this.bnToRatio(this.getApyByApr(apr));
    }
  }

  private max() {
    this.inputStake = this.walletBalance
      .div(`1e${this.inTokenDecimal}`)
      .integerValue(BigNumber.ROUND_FLOOR)
      .toNumber();
  }

  private async startStake() {
    if (this.inputStake <= 0) {
      return;
    }

    const amount = new BigNumber(`1e${this.inTokenDecimal}`).times(
      this.inputStake
    );
    await this.stake(amount);
    this.inputStake = 0;
    this.refreshPersonalInfo();
  }

  private async startWithdraw() {
    if (this.inputStake <= 0) {
      return;
    }

    const amount = new BigNumber(`1e${this.inTokenDecimal}`).times(
      this.inputStake
    );
    await this.withdraw(amount);
    this.inputStake = 0;
    this.refreshPersonalInfo();
  }

  private async startClaim() {
    if (this.myReward.lte(0)) {
      return;
    }

    await await this.getReward();
    this.refreshPersonalInfo();
  }
}
