import { render, staticRenderFns } from "./PlayNow.vue?vue&type=template&id=b33f6f6c&scoped=true&"
import script from "./PlayNow.vue?vue&type=script&lang=ts&"
export * from "./PlayNow.vue?vue&type=script&lang=ts&"
import style0 from "./PlayNow.vue?vue&type=style&index=0&lang=less&"
import style1 from "./PlayNow.vue?vue&type=style&index=1&id=b33f6f6c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b33f6f6c",
  null
  
)

export default component.exports