


















































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageHome",
})
export default class PageHome extends Vue {
  isShowComing = false;
  private openLink(url: string, noNewTab = false) {
    window.open(url, noNewTab ? "_self" : "_blank");
  }
  /**
   * 首页抽奖活动版本-转盘页面跳转逻辑。
   */
  // toRaffle(): void {
  //   this.$router.push("/raffle");
  // }
  /**
   * 水晶战争游戏版本-游戏跳转页面。
   */
  toCrystal(): void {
    if (process.env.VUE_APP_SERVER_GROUP === "development") {
      location.href = "http://localhost:3000/minerwar";
    } else if (process.env.VUE_APP_SERVER_GROUP === "production") {
      location.href = "https://dapp.kingdomx.co/minerwar";
    } else {
      location.href = "https://dapp-test.kingdomx.co/minerwar";
    }
  }
  public async Coming(): Promise<void> {
    if (this.isShowComing) return;
    this.isShowComing = true;
    await this.delay(10000);
    this.isShowComing = false;
  }

  private delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
}
