









































import { Component, Vue } from "vue-property-decorator";

import ContractCfg from "@/logic/contract-config";
import Miner from "@/components/miner/miner.vue";
import MinerOperator from "@/components/miner/operator.vue";
import { MinerPool } from "@/logic/contract-config/define";

const Names = ["KT", "KT-BUSD LP"];

@Component({
  name: "MinerPage",
  components: {
    Miner,
    MinerOperator,
  },
  data() {
    return {
      currentIndex: -1,
    };
  },
})
export default class MinerOldPage extends Vue {
  pool = [] as MinerPool[];
  names = [] as string[];

  created(): void {
    const p = [] as MinerPool[];
    const n = [] as string[];
    for (let i = 0; i < ContractCfg.MinerPool.length; ++i) {
      if (ContractCfg.MinerPool[i].hide) continue;
      p.push(ContractCfg.MinerPool[i]);
      n.push(Names[i]);
    }

    this.pool = p;
    this.names = n;
  }
}
