






























































































































































































































































































































































































































































































































































































































































































































































































































































































































// import router from "@/router";
import { Component, Vue, Mixins } from "vue-property-decorator";
import WalletMixin from "@/mixins/wallet";
import { Api } from "@/logic/api";
import ContractCfg from "@/logic/contract-config";
import Contract from "@/logic/block_chain/abstract/contract";
import BigNumber from "bignumber.js";

import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import { NationalNumber } from "libphonenumber-js";

interface boxList {
  activityId: number;
  boxId: number;
  opened: boolean;
}

interface UnLottery {
  eventId: string;
  type: number;
  activityId: number;
}

interface activityInfo {
  activityId: number;
  contractAddr: string;
}

interface activityBoxInfo {
  boxId: number;
  activityId: number;
}

interface chackAllowace {
  activityId: number;
  num: number;
  address: string;
  boxesId: Array<number>;
  boxActivityList: Array<activityBoxInfo>;
}

@Component({
  name: "raffle-page",
})
export default class RafflePage extends Mixins(WalletMixin) {
  LotteryConfig = ContractCfg.Lottery;
  KingtoCinfig = ContractCfg.KingToken;
  lotteryContractR!: Contract<MethodReadonly>;
  kingtokenContractR!: Contract<MethodReadonly>;

  allowanceToken = new BigNumber(0);
  raffleLotteryItem = {
    eventId: "",
    type: 0,
    knt: "0", // 开出的knt数量
    hero: true, // 是否开出英雄
    heroImg: "", // 英雄图片
    rarity: "", // 英雄品质
    signature: "",
    activityId: 0,
  };
  openKNTBoxInfo = {};
  RedeemReward = {};

  //活动信息列表
  ActiveList: activityInfo[] = [];
  nowShowStatus = "raffle";
  //
  ticketNum = 0;
  rodamNum = 0;
  //当前选择兑换的门票数
  exchangeTiketNum = 1;
  //已兑换门票数
  realexchangeTiketNum = 0;
  //钱包地址
  userAddress = "";
  //头部展示的地址信息
  headerAddress = "";
  //拉取钱包界面
  isShowPullWallat = true;
  //展示抽奖券为0
  isShowTicketNone = false;
  //展示抽奖获得的英雄
  isShowGetPrize = false;
  //展示成功领取奖品界面
  isShowGetPrizeSuccess = false;
  //展示抽奖券领取的情况
  isShowGetTicketSuccess = false;
  //抽到英雄的品质
  heroQuality = "";
  //判断是否正在抽奖
  isRunRaffle = false;
  //后台返回抽到对应的奖品
  PrizeNum = 0;
  //抽到金币的数量
  KNTNum = "0";
  //宝箱列表
  boxList: boxList[] = [];
  claimedList = [];
  //是否有领取宝箱资格 0-无，1-有
  getBoxQualificat = 0;
  //获取宝箱失败
  isShowUnGetBox = false;
  //获得宝箱成功
  isShowGetBox = false;
  //开启宝箱成功板块
  isShowGetKnt = false;
  isLogin = false;
  isShowWating = false;

  userKTNum = "0";
  userBoxNum = 0;
  nowEventId = "0";
  exchangeAble = false;
  passNum = 1000000000000000000;

  //需要在派生类里面重载的方法，必须要写
  protected onWalletReady(): Promise<void> | void {
    this.wallet.addAccountChangeHandler(this.onAccountChange.bind(this));
    if (this.currentAddress) {
      this.Oninit(this.currentAddress);
      if (!this.isLogin) {
        this.tologin();
        this.TiketIsChangeAble();
      }
    }
  }

  //监听到地址变化调用的方法
  private onAccountChange(oldAccount: string, newAcount: string) {
    if (newAcount != this.userAddress) {
      this.boxList = [];
      this.ticketNum = 0;
      this.userKTNum = "0";
      this.userBoxNum = 0;
      this.tologin();
      this.TiketIsChangeAble();
    }
    this.Oninit(newAcount);
  }

  //初始化以及获取当前状态的方法
  public async Oninit(address: string): Promise<void> {
    if (address != "") this.isShowPullWallat = false;
    this.userAddress = address;
    this.headerAddress = this.sliceString(address);
  }

  //用户登录获取cookie
  public async tologin(): Promise<void> {
    const currentaddress = this.currentAddress;
    const signMessage = await Api.getSignMessage();
    let signature = await this.wallet.signPersonalMessage(
      signMessage.signMessage,
      currentaddress
    );
    let logininfo = await Api.doChainUserLogin(
      signMessage.uuid,
      signature,
      this.currentAddress
    );
    if (logininfo == "OK") this.isLogin = true;
    this.getBaseInfo();
  }

  //获取活动页面宝箱和待领取信息
  public async getBaseInfo(): Promise<void> {
    //获取活动信息
    let ActiveList = await Api.getLotteryActivityList();
    this.ActiveList = ActiveList.activities;

    //获取宝箱信息
    let boxes = await Api.getBoxesInfo();
    this.boxList = boxes.boxes;
    this.userBoxNum = this.boxList.length;

    // 获得未开奖的事件列表, 进行批量开奖;
    let list = await Api.getLotteryList();
    let length = list.events.length;
    if (length != 0) {
      for (let i = 0; i < length; i++) {
        var item: UnLottery = list.events[i];
        var itemRes = await Api.notifyLotteryEvent(
          item.activityId,
          item.eventId,
          item.type
        );
        if (itemRes != "OK") {
          // this.isShowWating = false;
          continue;
        }
        await Api.doLottery(item.activityId, item.eventId, item.type);
      }
    }

    //获取未领取的奖品信息
    let Unclaimed = await Api.getUnClaimRewards();
    this.claimedList = Unclaimed.rewards;
    // this.isShowWating = false;
    this.TiketIsChangeAble();
    this.getTicketInfo();
  }
  //获取多期合约中的门票数量
  private async getContractTicketNum(ContractAddr: string): Promise<number> {
    let lotteryContractR = this.wallet.ReadonlyBlockChain.createContract(
      ContractAddr,
      this.LotteryConfig.Contract.abis
    );

    let nowNum: any = await lotteryContractR.methods
      .getTicketAmount(this.userAddress)
      .call();

    return nowNum;
  }

  //刷新门票和KT信息
  private async getTicketInfo() {
    //从链上获取门票数量
    this.createContract();
    let ticketnum = 0;
    for (var i = 0; i < this.ActiveList.length; i++) {
      let item: activityInfo = this.ActiveList[i];
      let nowTicketNum = await this.getContractTicketNum(item.contractAddr);
      // console.log("itemticket" + nowTicketNum);
      ticketnum += Number(nowTicketNum);
    }
    //设置门票数量
    this.ticketNum = ticketnum;

    //设置KT数量  info.slice
    let KTNum: any = await this.kingtokenContractR.methods
      .balanceOf(this.userAddress)
      .call();
    let showKtNum = String(KTNum / this.passNum);
    if (showKtNum.indexOf(".") > -1) {
      this.userKTNum = (KTNum / this.passNum).toFixed(3);
    } else {
      this.userKTNum = showKtNum;
    }
    this.TiketIsChangeAble();
  }

  //创建合约对象
  private createContract() {
    // this.lotteryContractR = this.wallet.ReadonlyBlockChain.createContract(
    //   this.LotteryConfig.Contract.address,
    //   this.LotteryConfig.Contract.abis
    // );
    this.kingtokenContractR = this.wallet.ReadonlyBlockChain.createContract(
      this.KingtoCinfig.Contract.address,
      this.KingtoCinfig.Contract.abis
    );
  }

  //兑换抽奖券数量变化
  public changeTicketNum(type: number): void {
    if (!type) {
      if (this.exchangeTiketNum > 1) {
        this.exchangeTiketNum -= 1;
      }
    } else {
      if (this.completeMaxTicket(this.exchangeTiketNum)) {
        this.exchangeTiketNum += 1;
      }
    }
  }

  //计算KT
  public countKT(kT: string): string {
    return kT.substring(0, kT.length - 18);
  }

  //兑换抽奖券逻辑
  public async exchangeTicket(): Promise<void> {
    //判断是否是可兑换状态
    if (!this.exchangeAble) return;
    //判断兑换数量是否为0
    if (this.exchangeTiketNum == 0) return;
    if (!this.wallet.WritableBlockChain) return;
    //开启等待界面
    this.isShowWating = true;
    let num = this.exchangeTiketNum;
    let activityBoxList: activityBoxInfo[] = [];
    let getBoxList = this.boxList.slice(0, this.exchangeTiketNum);
    //计算所选宝箱的活动信息
    for (var item in getBoxList) {
      activityBoxList.push({
        boxId: getBoxList[item].boxId,
        activityId: getBoxList[item].activityId,
      });
      this.boxList.shift();
    }

    //处理每个合约选择的宝箱操作的数据
    let chackAllowace: chackAllowace[] = [];
    for (var i = 0; i < this.ActiveList.length; i++) {
      var ActivityItem: activityInfo = this.ActiveList[i];
      var size = 0;
      //属于该活动id的宝箱列表
      var boxesInfo: activityBoxInfo[] = [];
      var boxesIds = [];
      for (var chackId = 0; chackId < activityBoxList.length; chackId++) {
        var activityBox: activityBoxInfo = activityBoxList[chackId];
        if (ActivityItem.activityId == activityBox.activityId) {
          size += 1;
          boxesIds.push(activityBox.boxId);
          boxesInfo.push(activityBox);
        }
      }
      if (size != 0) {
        var pushItem: chackAllowace = {
          activityId: this.ActiveList[i].activityId,
          num: size,
          boxesId: boxesIds,
          address: ActivityItem.contractAddr,
          boxActivityList: boxesInfo,
        };
        chackAllowace.push(pushItem);
      }
    }

    //根据获取的需要处理的合约数量进行操作
    for (var Cai = 0; Cai < chackAllowace.length; Cai++) {
      let chackAllowaceItem = chackAllowace[Cai];

      let allowanceTkNum: any = await this.kingtokenContractR.methods
        .allowance(this.currentAddress, chackAllowaceItem.address)
        .call();
      var allowanceTkNumItem = new BigNumber(allowanceTkNum);

      //创建活动只读合约
      let lotteryContractR = this.wallet.ReadonlyBlockChain.createContract(
        chackAllowaceItem.address,
        this.LotteryConfig.Contract.abis
      );
      //获取合约上兑换一个宝箱需要的kt
      let _exchangeTicketKTAmount: any = await lotteryContractR.methods
        ._exchangeTicketKTAmount()
        .call();
      let exchangeTicketKTAmount = new BigNumber(_exchangeTicketKTAmount);
      //计算该合约消耗宝箱的数量总共的kt
      let realNeedKTAmount = exchangeTicketKTAmount.times(
        chackAllowaceItem.num
      );
      //如果已授权的kt数不满足
      if (!(allowanceTkNumItem.comparedTo(realNeedKTAmount) > -1)) {
        let phase = realNeedKTAmount.minus(allowanceTkNumItem);
        //创建KT的可写入合约
        const inContract = this.wallet.WritableBlockChain.createContract(
          this.KingtoCinfig.Contract.address,
          this.KingtoCinfig.Contract.abis
        );
        await inContract.methods
          .approve(chackAllowaceItem.address, phase.toString(10))
          .send();
      }

      //创建lottery的可写入合约
      const writeAbleBlock: any = this.createContractByid(
        chackAllowaceItem.activityId
      );

      const merkelProof = await Api.getMerkelProof(
        chackAllowaceItem.boxActivityList
      );
      try {
        await writeAbleBlock.methods
          .exchangeTicketList(
            chackAllowaceItem.num,
            merkelProof.merkelProof,
            chackAllowaceItem.boxesId
          )
          .send();
      } catch (e) {
        num -= num;
        this.exchangeTiketNum = 1;
        this.isShowWating = false;
      }
    }

    this.realexchangeTiketNum = num;
    this.exchangeTiketNum = 1;
    this.isShowWating = false;
    if (num != 0) this.isShowGetTicketSuccess = true;
    //先更新数据在判断是否可以继续兑换
    this.getTicketInfo();
    this.getBaseInfo();
    this.TiketIsChangeAble();
  }

  //检查是否还可兑换门票
  private TiketIsChangeAble(): void {
    let userKTNum = Number(this.userKTNum);
    if (userKTNum < 100 || this.userBoxNum < 1) this.exchangeAble = false;
    else this.exchangeAble = true;
  }

  //抽奖界面、换票、开箱界面切换逻辑
  public changeShow(type: string): void {
    this.nowShowStatus = type;
  }
  //开始抽奖逻辑
  public async startRaffle(): Promise<void> {
    if (!this.currentAddress) {
      this.isShowPullWallat = true;
      return;
    }
    if (!this.wallet.WritableBlockChain) return;

    //判断是否正在抽奖
    if (this.isRunRaffle) return;

    //当前选择的活动信息
    let nowSelectActivityInfo: activityInfo = {
      activityId: 0,
      contractAddr: "",
    };

    let prizeNum = 0;
    for (var i = 0; i < this.ActiveList.length; i++) {
      let num = await this.getContractTicketNum(
        this.ActiveList[i].contractAddr
      );
      if (num != 0) {
        nowSelectActivityInfo = this.ActiveList[i];
        break;
      }
    }
    //如果没有找到合适的合约则返回
    if (nowSelectActivityInfo.contractAddr == "") {
      return;
    }

    //创建可以写入合约
    const LotteryWritAble = this.wallet.WritableBlockChain.createContract(
      nowSelectActivityInfo.contractAddr,
      this.LotteryConfig.Contract.abis
    );
    this.isShowWating = true;
    let response = await LotteryWritAble.methods
      .useTicket(1)
      .send({ returnReceipt: true });
    if (!response.events) {
      return;
    }
    // console.log(response);
    let eventId = response.events.UseTicket.returnValues.id;
    const notifyOpenBox = await Api.notifyLotteryEvent(
      nowSelectActivityInfo.activityId,
      eventId,
      1
    );

    if (notifyOpenBox != "OK") {
      this.isShowWating = false;
      return;
    }
    const getDoLottery = await Api.doLottery(
      nowSelectActivityInfo.activityId,
      eventId,
      1
    );
    if (!getDoLottery.type) {
      this.isShowWating = false;
      return;
    }
    // console.log(getDoLottery);

    this.raffleLotteryItem = getDoLottery;
    //如果奖品为金币
    if (!this.raffleLotteryItem.hero) {
      var countKt = this.countKT(this.raffleLotteryItem.knt);
      var kntNum = Number(countKt);
      if (kntNum >= 2800) {
        prizeNum = 7;
      } else if (kntNum >= 588) {
        prizeNum = 3;
      } else {
        prizeNum = 1;
      }
      this.KNTNum = countKt;
    } else {
      var indexes: any[] = [];
      if (this.raffleLotteryItem.rarity == "Common") indexes = [0, 5];
      if (this.raffleLotteryItem.rarity == "Rare") indexes = [2, 9];
      if (this.raffleLotteryItem.rarity == "Epic") indexes = [4];
      if (this.raffleLotteryItem.rarity == "Legendary") indexes = [6];
      if (this.raffleLotteryItem.rarity == "Mythic") indexes = [8];
      this.heroQuality = this.raffleLotteryItem.rarity;
      prizeNum = indexes[Math.floor(Math.random() * indexes.length)];
    }
    this.isShowWating = false;
    this.isRunRaffle = true;
    //如果有票执行抽奖
    if (this.ticketNum != 0) {
      this.ticketNum -= 1;
      this.PrizeNum = prizeNum + 1;
      await this.runRaffle(50 + prizeNum);

      this.isShowGetPrize = true;
    } else {
      this.isShowTicketNone = true;
    }

    this.isRunRaffle = false;
  }
  //关闭窗口逻辑
  public close(type: string): void {
    if (type == "notes") this.isShowTicketNone = false;
    if (type == "getprize") {
      this.isShowGetPrize = false;
      this.getBaseInfo();
    }
    if (type == "ungetbox") this.isShowUnGetBox = false;
    if (type == "getbox") this.isShowGetBox = false;
    if (type == "getknt") {
      this.isShowGetKnt = false;
      this.getBaseInfo();
    }
    if (type == "wallat") this.isShowPullWallat = false;
    if (type == "success") this.isShowGetPrizeSuccess = false;
    if (type == "ticket") this.isShowGetTicketSuccess = false;
    if (type == "wait") this.isShowWating = false;
    this.openKNTBoxInfo = {};
  }

  //通过活动id创建可写合约
  private createContractByid(activityId: number) {
    if (!this.wallet.WritableBlockChain) return;
    let address = "";
    for (var i = 0; i < this.ActiveList.length; i++) {
      var activityInfo: activityInfo = this.ActiveList[i];
      if (activityInfo.activityId == activityId) {
        address = activityInfo.contractAddr;
        break;
      }
    }
    return this.wallet.WritableBlockChain.createContract(
      address,
      this.LotteryConfig.Contract.abis
    );
  }

  //开启kntBox逻辑
  public async openbox(boxId: number, activityId: number): Promise<void> {
    if (!this.wallet.WritableBlockChain) return;
    let boxesIdInfo = [];
    let boxesId = [boxId];
    boxesIdInfo.push({
      boxId: boxId,
      activityId: activityId,
    });

    let merkelProof = await Api.getMerkelProof(boxesIdInfo);

    //创建可写入的合约对象
    // const writeAbleBlock = this.wallet.WritableBlockChain.createContract(
    //   this.LotteryConfig.Contract.address,
    //   this.LotteryConfig.Contract.abis
    // );

    const writeAbleBlock: any = this.createContractByid(activityId);
    this.isShowWating = true;
    let response = await writeAbleBlock.methods
      .openMysteryBoxList(1, merkelProof.merkelProof, boxesId)
      .send({ returnReceipt: true });

    if (!response.events) {
      return;
    }

    let eventId = response.events.OpenMysteryBox.returnValues.id;
    const notifyOpenBox = await Api.notifyLotteryEvent(activityId, eventId, 0);

    if (notifyOpenBox != "OK") {
      this.isShowWating = false;
      return;
    }
    this.nowEventId = eventId;
    const openResult = await Api.doLottery(activityId, eventId, 0);
    this.openKNTBoxInfo = openResult;
    this.isShowWating = false;
    // console.log(openResult);
    this.KNTNum = this.countKT(openResult.knt);
    this.getBaseInfo();
    this.isShowGetKnt = true;
  }
  //领取宝箱逻辑 mint切页（已撤销）
  public getBox(): void {
    if (this.getBoxQualificat) {
      this.isShowGetBox = true;
    } else {
      this.isShowUnGetBox = true;
    }
  }
  //计算最大兑换抽奖券逻辑
  private completeMaxTicket(exchangeNum: number) {
    var nextNum = (exchangeNum += 1);
    let userKTNum = Number(this.userKTNum);
    if (nextNum * 100 <= userKTNum && nextNum <= this.userBoxNum) return true;
    else return false;
  }
  //延时逻辑 用于抽奖逻辑使用
  private delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  //切割地址信息为头部展示
  private sliceString(info: string) {
    return info.slice(0, 6) + " ..." + info.slice(-4);
  }
  //转盘开始旋转逻辑 用于抽奖逻辑使用
  private async runRaffle(runNum: number) {
    let centNum = Math.ceil(runNum / 2);
    let speedUp = 0;
    let speedDo = 0;
    let delaytime = 600;

    for (let i = 0; i <= runNum; i++) {
      if (i < centNum) {
        if (delaytime >= 150) delaytime = 600 - speedUp * 50;
        speedUp += 1;
      }
      if (i >= runNum - 10) {
        if (delaytime <= 900) delaytime = 200 + speedDo * 80;
        speedDo += 1;
      }

      await this.delay(delaytime);
      this.rodamNum = i % 10;
    }
    await this.delay(1000);
  }
  //领取奖励逻辑
  public async Redeem(
    eventId: string,
    type: number,
    activityId: number
  ): Promise<void> {
    if (!this.wallet.WritableBlockChain) return;

    let getReward = await Api.getReward(eventId, type, activityId);
    this.isShowWating = true;
    const writeAbleBlock: any = this.createContractByid(activityId);
    this.RedeemReward = getReward;
    let getKt;
    if (getReward.knt) {
      getKt = new BigNumber(getReward.knt);
    } else {
      getKt = new BigNumber(0);
    }
    if (!getReward.type) {
      await writeAbleBlock.methods
        .getMysterBoxReward(
          getReward.signature,
          this.userAddress,
          getReward.eventId,
          getKt.toString(10)
        )
        .send();
    } else {
      let mintHero;
      if (getReward.hero) mintHero = "1";
      else mintHero = "0";
      await writeAbleBlock.methods
        .getTicketReward(
          getReward.signature,
          this.userAddress,
          getReward.eventId,
          getKt.toString(10),
          mintHero
        )
        .send();
    }
    this.close("getknt");
    this.close("getprize");
    this.isShowWating = false;
    this.isShowGetPrizeSuccess = true;
    this.getBaseInfo();
  }
}
