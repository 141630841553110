
















































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PageFaq",
})
export default class PageFaq extends Vue {
  private quesionIndex = 0;

  private setQuesionIndex(index: number) {
    this.quesionIndex = index;
  }
}
