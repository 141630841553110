import Web3 from "web3";
import { AbstractProvider } from "web3-core";
import detectEthereumProvider from "@metamask/detect-provider";

import Wallet from "../abstract/wallet";
import BlockChain from "../abstract/block_chain";
import Web3BlockChain from "./web3_block_chain";
import Web3MethodReadonly from "./web3_method_readonly";
import Web3Method from "./web3_method";
import { ChainConfig } from "../abstract/define";

class Web3Wallet extends Wallet {
  private web3Readonly?: Web3;
  private web3?: Web3;

  createReadonlyBlockChain(): BlockChain<Web3MethodReadonly> {
    if (!this.web3Readonly) {
      this.web3Readonly = new Web3(this._nodeUrl);
    }

    return new Web3BlockChain(
      this.web3Readonly,
      this.getAddress.bind(this),
      Web3MethodReadonly
    );
  }

  async createWalletBlockChain(): Promise<BlockChain<Web3Method> | undefined> {
    if (!window.ethereum) {
      return;
    }

    try {
      const provider = (await detectEthereumProvider()) as AbstractProvider;
      if (!this.web3) {
        this.web3 = new Web3(provider);
      }

      return new Web3BlockChain(
        this.web3,
        this.getAddress.bind(this),
        Web3Method
      );
    } catch (e) {
      console.error(e);
    }
  }

  public async authorize(): Promise<boolean> {
    if (!window.ethereum) {
      return false;
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ethereum = window.ethereum as any;
      const accounts: string[] = await ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts && accounts.length > 0) {
        return true;
      }
    } catch (e) {
      console.error("Failed to request accounts from ethereum.", e);
    }
    return false;
  }

  async changeNetwork(cfg: ChainConfig): Promise<boolean> {
    if (!window.ethereum) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const request = (window.ethereum as any).request;
    try {
      await request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: cfg.chainId }],
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = e as any;
      if (err.code === 4902) {
        try {
          await request({
            method: "wallet_addEthereumChain",
            params: [cfg],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
      return true;
    }
  }

  public getAddress(): string {
    if (!window.ethereum) {
      return "";
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ethereum = window.ethereum as any;
    return ethereum.selectedAddress || "";
  }

  public getChainId(): string {
    if (!window.ethereum) {
      return "";
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ethereum = window.ethereum as any;
    return ethereum.chainId || "";
  }

  public async signPersonalMessage(
    message: string,
    account?: string
  ): Promise<string> {
    if (!this.web3) throw new Error("Wallet is not install or ready.");

    account = account ?? this.getAddress();
    return await this.web3.eth.personal.sign(message, account, "");
  }
}

export default Web3Wallet;
