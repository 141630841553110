




















































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ContractCfg from "@/logic/contract-config";
import Contract from "@/logic/block_chain/abstract/contract";
import MethodReadonly from "@/logic/block_chain/abstract/method_readonly";
import { DtoBoxInfo } from "@/logic/api/dto/dto";
import { DtoActivityInfo, SellActivityType } from "@/logic/api/dto/sell.dto";
import WalletMixin from "@/mixins/wallet";
import { Api } from "@/logic/api";
import BigNumber from "bignumber.js";

import PageFeatures from "@/components/page/page-features.vue";
import RedeemPage from "./redeem-page.vue";

import { ERC721PermitAbi } from "@/logic/contract-config/abi";

interface HeroInfo {
  nftId: BigNumber;
  cfg: unknown;
}

interface SellActivityData {
  inWhiteList: boolean;
  buy: boolean;
  price: BigNumber;
  approve: BigNumber;
}

interface ExchangeActivityData {
  nftList: BigNumber[];
}

interface ActivityInfo {
  info: DtoActivityInfo;
  data: SellActivityData | ExchangeActivityData;
}

interface NftChooseItem {
  nftId: BigNumber;
  info: DtoBoxInfo;
}

@Component({
  components: {
    RedeemPage,
  },
})
export default class Collection extends Mixins(WalletMixin) {
  isShowCollectListPanel = false;
  isShowCollectDetailPanel = false;
  isShowCollectNFTPanel = false;
  isShowCollectionsBody = true;
  isShowCollectHeroDetailPanel = false; // 是否显示收藏英雄详情面板
  curActiveHeroIndex = -1; // 当前选中的英雄下标
  isShowHeroSkillPanel = false; // 是否显示英雄技能面板
  isShowMbHeroInfo = false;
  activityList: ActivityInfo[] = [];
  now = 0;
  chooseNftList: any = [];
  selectedNftBoxIndex = -1; // 选中的领取宝箱下标
  isShowExchangeBoxDetailDialog = false; // 是否显示宝箱详情弹框

  isShowMobileBoxDetailPanel = false; // 移动端是否显示宝箱详情弹框

  activeTab = 1; // 0: Hero 1: box
  // wallet
  heroContractR!: Contract<MethodReadonly>;
  heroList: HeroInfo[] = [];

  public created(): void {
    // 创建只读合约对象，用于获取链上信息
    this.heroContractR = this.wallet.ReadonlyBlockChain.createContract(
      ContractCfg.Hero.Contract.address,
      ContractCfg.Hero.Contract.abis
    );

    // 添加账号变更的会带哦
    this.wallet.addAccountChangeHandler(this.onAccountChg.bind(this));
    this.onInit();
  }

  protected onWalletReady(): void {
    this.refreshHeroList(); // 获取英雄列表
    this.refreshAllActivity(); // 获取宝箱列表
  }

  // 相应账户变化
  protected async onAccountChg(): Promise<void> {
    await this.refreshHeroList(); // 获取英雄列表
    await this.refreshAllActivity(); // 获取宝箱列表
  }

  public async onInit(): Promise<void> {
    // 定时器刷新now
    setInterval(() => {
      this.now = Math.trunc(Date.now() / 1000);
    }, 1000);

    // 从中心化服务器，请求所有活动列表，但活动信息缺少与用户相关的信息
    const result = await Api.getSellActivityList();

    this.activityList = result.list
      .map((v) => ({
        info: v,
        data: this.getEmptyActivityData(v.type),
      }))
      // 过滤出领取的活动 type = 0，售卖； type = 1，领取；
      .filter((item) => {
        return item.info.type === 1;
      });

    this._getNftList(); // 获取 nft 宝箱列表
  }

  // 更新所有活动信息
  private async refreshAllActivity() {
    if (this.currentAddress) {
      this._getNftList(); // 获取 nft 宝箱列表
    } else {
      // 钱包未授权，清空所有活动信息
      for (const activity of this.activityList) {
        activity.data = this.getEmptyActivityData(activity.info.type);
      }
    }
  }

  private getEmptyActivityData(type: SellActivityType) {
    switch (type) {
      case SellActivityType.Sell:
        return {
          inWhiteList: false,
          buy: false,
          price: new BigNumber(0),
          approve: new BigNumber(0),
        } as SellActivityData;
      case SellActivityType.Exchange:
        return {
          nftList: [],
        } as ExchangeActivityData;
      default:
        throw new Error(`Invalid sell activity type(${type})`);
    }
  }

  // 获取 nft 宝箱列表
  protected async _getNftList(): Promise<void> {
    let tempList = [];
    for (let activity of this.activityList) {
      const nftList = await this.refreshExchangeActivity(activity);
      if (nftList) tempList.push(...nftList);
    }

    this.chooseNftList = tempList;
  }

  // redmeed 1.获取宝箱列表
  private async refreshExchangeActivity(activity: ActivityInfo) {
    if (!this.currentAddress) return;

    const inContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const [nftNum] = await Promise.all([
      this.methodCallToInt(inContract.methods.balanceOf(this.currentAddress)),
    ]);

    const list: BigNumber[] = [];
    const promisesA: Promise<BigNumber>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promisesA.push(
        this.methodCallToBN(
          inContract.methods.tokenOfOwnerByIndex(this.currentAddress, i)
        )
      );

      if (promisesA.length >= 10 || i === nftNum - 1) {
        const nftIds = await Promise.all(promisesA);
        list.push(...nftIds);
      }
    }
    console.log(list);

    const nftContract = this.wallet.ReadonlyBlockChain.createContract(
      activity.info.inContract,
      ERC721PermitAbi
    );

    const getBoxData = async (
      contract: Contract<MethodReadonly>,
      nftId: BigNumber
    ): Promise<NftChooseItem> => ({
      nftId,
      info: await this.getNftInfo<DtoBoxInfo>(contract, nftId),
    });

    const promisesB: Promise<NftChooseItem>[] = [];
    for (const nftId of list) {
      promisesB.push(getBoxData(nftContract, nftId));
    }

    const listAry = await Promise.all(promisesB);

    return listAry.map((item: any) => {
      return {
        nftId: item.nftId,
        info: item.info,
        activityInfo: activity.info,
      };
    });
  }

  showMbHeros() {
    this.isShowMbHeroInfo = true;
  }

  // 更新英雄列表
  private async refreshHeroList() {
    this.heroList = [];
    console.log(this.heroList);

    if (!this.currentAddress) {
      this.heroList = [];

      return;
    }

    const nftNum = await this.methodCallToInt(
      this.heroContractR.methods.balanceOf(this.currentAddress)
    );

    if (nftNum <= 0) {
      this.heroList = [];
      return;
    }

    const promises: Promise<HeroInfo>[] = [];
    for (let i = 0; i < nftNum; ++i) {
      promises.push(this.getHeroInfo(i));
    }

    this.heroList = await Promise.all(promises);
  }

  // 获取英雄信息
  private async getHeroInfo(index: number): Promise<HeroInfo> {
    const nftId = await this.methodCallToBN(
      this.heroContractR.methods.tokenOfOwnerByIndex(this.currentAddress, index)
    );

    const heroInfo = await Api.getHeroInfo(nftId);
    const cfg = typeof heroInfo === "string" ? JSON.parse(heroInfo) : heroInfo;
    console.log(index);
    return {
      nftId: nftId,
      cfg,
    };
  }

  openCollectListPanel(): void {
    this.isShowCollectListPanel = true;
  }

  openCollectDetailPanel(): void {
    this.isShowCollectDetailPanel = true;
  }

  // 打开英雄详情面板
  openCollectHeroDetailPanel(heroIndex: number): void {
    this.curActiveHeroIndex = heroIndex;
    this.isShowCollectHeroDetailPanel = true;
  }

  // pc 打开宝箱详情
  openExchangeBoxDetailDialog(nftIndex: number): void {
    this.selectedNftBoxIndex = nftIndex;
    this.isShowExchangeBoxDetailDialog = true;
    this.isShowCollectionsBody = false;
  }

  // pc 关闭领取宝箱弹框
  closeExchangeBoxDetailDialog(): void {
    this.selectedNftBoxIndex = -1;
    this.isShowExchangeBoxDetailDialog = false;
  }

  // 移动端 打开宝箱详情
  openMobileExchangeBoxDetailDialog(nftIndex: number): void {
    this.selectedNftBoxIndex = nftIndex;
    // this.isShowMobileBoxDetailPanel = true;
    this.isShowExchangeBoxDetailDialog = true;
  }

  // 移动端 关闭领取宝箱弹框
  closeMobileExchangeBoxDetailDialog(): void {
    this.selectedNftBoxIndex = -1;
    this.isShowMobileBoxDetailPanel = false;
  }

  openCollectNFTPanel(): void {
    this.isShowCollectNFTPanel = true;
  }

  switchTab(tab: number): void {
    this.activeTab = tab;
  }

  private openLink(url: string) {
    window.open(url, "_blank");
  }
}
