

















import { Component, Vue } from "vue-property-decorator";

const CookieStorageName = "accept-cookie";

@Component({
  name: "Cookie",
})
export default class Cookie extends Vue {
  private showCookie = this.isAcceptCookie();

  private isAcceptCookie() {
    return !window.localStorage.getItem(CookieStorageName);
  }

  private acceptCookie() {
    window.localStorage.setItem(CookieStorageName, "true");
    this.showCookie = false;
  }
}
