import Vue from "vue";

export const PageAnchor = [
  "home",
  "overview",
  "features",
  "gameplay",
  "roadmap",
  "token",
  "allocation",
  "partner",
  "list",
  "faq",
];

export function onNavigate(this: Vue, index: number): void {
  if (this.$route.name === "Home") {
    window.history.pushState({}, "", `#${PageAnchor[index]}`);

    const app = this.$router.app.$children[0];
    const homePage = app.$children.find(
      (v) => v.$options.name === "HomePage"
    ) as Vue | undefined;

    if (homePage === undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (homePage as any).navigateTo(index);
  } else {
    if (window.location.hash.slice(1) === PageAnchor[index]) return;

    this.$router.push({
      name: "Home",
      hash: `#${PageAnchor[index]}`,
    });
  }
}
