import PublishCfg from "./publish";
import TestCfg from "./test";

const supportGroup = ["test", "publish"];

function getCfg() {
  console.log(process.env.VUE_APP_CHAIN_GROUP);
  const chainGroup = (process.env.VUE_APP_CHAIN_GROUP || "").toLowerCase();

  switch (chainGroup) {
    case "test":
      return TestCfg;
    case "publish":
      return PublishCfg;
    default:
      throw new Error(
        `expect 'env.VUE_APP_CHAIN_GROUP' is in [${supportGroup
          .map((value) => `"${value}"`)
          .join(",")}]`
      );
  }
}

const ContractCfg = getCfg();
export default ContractCfg;
