







































import { Component, Vue } from "vue-property-decorator";

import FullMask from "@/components/FullMask.vue";
import Navbar from "@/components/Navbar.vue";

import PageHome from "@/components/page/page-home.vue";
import PageOverview from "@/components/page/page-overview.vue";
import PageFeatures from "@/components/page/page-features.vue";
import PageGameplay from "@/components/page/page-gameplay.vue";
import PageRoadmap from "@/components/page/page-roadmap.vue";
import PageToken from "@/components/page/page-token.vue";
import PageAllocation from "@/components/page/page-allocation.vue";
import PagePartner from "@/components/page/page-partner.vue";
import PageList from "@/components/page/page-list.vue";
import PageFaq from "@/components/page/page-faq.vue";
import PlayNow from "@/components/PlayNow.vue";

import { PageAnchor } from "@/logic/define";
import BgVideoPlayer from "@/components/BgVideoPlayer.vue";

interface Section {
  anchor: null;
  index: number;
  isLast: false;
  item: Element;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Slide = Section;
type SectionDirection = "up" | "down";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type SlideDirection = "right" | "left";

const navNumber = 10;

@Component({
  name: "HomePage",
  components: {
    FullMask,
    Navbar,
    BgVideoPlayer,
    PageHome,
    PageOverview,
    PageFeatures,
    PageGameplay,
    PageRoadmap,
    PageToken,
    PageAllocation,
    PagePartner,
    PageList,
    PageFaq,
    PlayNow,
  },
  mounted(this: HomePage) {
    this.initFullpage();
  },
  beforeDestroy(this: HomePage) {
    this.uninitFullpage();
  },
})
export default class HomePage extends Vue {
  private showTerms = false;
  private showProvacy = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fullpageObj: any;
  private navNumber = navNumber;
  private navActiveIndex = 0;
  private isLast = false;
  private onHashChangeHandler = this.onHashChange.bind(this);

  private initFullpage() {
    if (this.fullpageObj) return;

    const fullpage = window.fullpage;
    this.fullpageObj = new fullpage("#fullpage", {
      anchors: PageAnchor,
      onLeave: (
        origin: Section,
        destination: Section,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        direction: SectionDirection
      ) => {
        this.navActiveIndex = destination.index;
        this.isLast = destination.isLast;
        let aspectRatio = window.innerWidth / window.innerHeight;

        if (this.$refs.bgVideoPlayer) {
          const bgVideoPlayer = this.$refs.bgVideoPlayer as BgVideoPlayer;
          bgVideoPlayer.showVideo(
            aspectRatio >= 4 / 3,
            destination.index === 0
          );
        }
        if (this.$refs.pageOverview) {
          const pageOverview = this.$refs.pageOverview as PageOverview;
          origin.index === 1 && pageOverview.setSpineRendering(false);
          destination.index === 1 && pageOverview.setSpineRendering(true);
        }
      },
    });

    // console.log("add hashchangehanderl", this.onHashChangeHandler);
    // window.addEventListener("hashchange", this.onHashChangeHandler);
  }

  private uninitFullpage() {
    if (!this.fullpageObj) return;

    this.fullpageObj.destroy("all");
    this.fullpageObj = null;

    // console.log("remove hashchangehanderl");
    // window.removeEventListener("hashchange", this.onHashChangeHandler, true);
  }

  private navigateTo(index: number) {
    this.fullpageObj.moveTo(index + 1);
  }

  private nextSection() {
    if (!this.fullpageObj) return;

    this.fullpageObj.moveSectionDown();
  }

  private onHashChange() {
    if (!this.fullpageObj) return;

    const hash = window.location.hash.slice(1);

    const index = PageAnchor.indexOf(hash);
    if (index === -1) return;

    this.navigateTo(index);
  }
}
