








import { Component, Vue } from "vue-property-decorator";

import Cookie from "@/components/cookie.vue";
import Header from "@/components/Header.vue";

@Component({
  name: "App",
  components: {
    Header,
    Cookie,
  },
})
export default class App extends Vue {
  hideHeaderRoute = ["Unlock", "BadgeTest", "WhiteListTest"];
}
