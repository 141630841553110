import { Axios, AxiosResponse } from "axios";
import BigNumber from "bignumber.js";
// import Web3 from "web3";
import {
  DtoBadgeActivityList,
  DtoEnabledBadgeActivityList,
  DtoReqDoLogin,
  DtoCreateAccount,
  DtoRspBadgeByIds,
  DtoRspBadgeInfo,
  DtoRspGetSignMessage,
  DtoRspIsLogin,
  DtoResetPwd,
} from "./dto/dto";
import {
  DtoSellInWhiteList,
  DtoSellActivityList,
  DtoGetVerifyMessageRsp,
  DtoGetSellTransacitonRsp,
  // DtoGetExchangeTransacitonRsp,
} from "./dto/sell.dto";

interface RspHttpError {
  statusCode: number;
  message: string;
}
interface activityBoxInfo {
  boxId: number;
  activityId: number;
}

class HttpError extends Error {
  constructor(err: RspHttpError) {
    super(
      `Server return an error. Code: ${err.statusCode}, Message: ${err.message}`
    );
  }
}

class HttpError403 extends HttpError {
  constructor() {
    super({
      statusCode: 403,
      message: "Access Forbidden(Invalid Authorization)",
    });
  }
}

const baseUrl = (() => {
  const serverGroup = process.env.VUE_APP_SERVER_GROUP?.toLowerCase();
  if (serverGroup === "test") {
    return "https://home-test-api.kingdomx.co";
  } else if (serverGroup === "production") {
    // return "https://home-api.kingdomx.co";
    return "/api";
  } else {
    return "/api";
  }
})();

class ApiClass {
  public readonly jsonHeader = "application/json";
  private readonly baseUrl = baseUrl;
  private readonly axios: Axios;

  constructor() {
    // const protocol = window.location.protocol;
    // const host = window.location.host;
    // const port = window.location.port;
    this.axios = new Axios({
      // url: `${protocol}://${host}:${port}/`,
      baseURL: this.baseUrl,
    });
  }

  private dealRsult<T>(result: AxiosResponse) {
    // console.log(result.data);
    if (result.status >= 200 && result.status < 300) {
      if (result.headers["content-type"].includes(this.jsonHeader)) {
        return JSON.parse(result.data) as T;
      } else {
        return result.data;
      }
    } else {
      const rsp = JSON.parse(result.data) as RspHttpError;
      if (result.status === 403) {
        window.localStorage.removeItem("authorization");
        throw new HttpError403();
      }

      throw new HttpError(rsp);
    }
  }

  async get<T>(path: string) {
    const result = await this.axios.get(path, {
      headers: {
        Accept: "application/json",
      },
    });

    return this.dealRsult<T>(result);
  }

  async post<T>(path: string, data: unknown) {
    const result = await this.axios.post(path, JSON.stringify(data), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return this.dealRsult<T>(result);
  }

  getSignMessage(): Promise<DtoRspGetSignMessage> {
    return this.post<DtoRspGetSignMessage>("/login/getSignMessage", {});
  }

  doLogin(request: DtoReqDoLogin): Promise<"OK"> {
    return this.post<"OK">("/login/doLogin", request);
  }

  createAccount(request: DtoCreateAccount): Promise<any> {
    const res = this.post<any>("/login/createAccount", request);
    return res;
  }

  async isLogined(): Promise<DtoRspIsLogin | undefined> {
    try {
      return await this.post<DtoRspIsLogin>("/login/isLogined", {});
    } catch (e) {
      if (e instanceof HttpError403) {
        return;
      } else {
        throw e;
      }
    }
  }

  getBadgeInfo(id: string): Promise<DtoRspBadgeInfo> {
    return this.get<DtoRspBadgeInfo>(`/badge/info?id=${id}`);
  }

  getBadgeByIds(ids: string[]): Promise<DtoRspBadgeByIds> {
    return this.get<DtoRspBadgeInfo>(
      `/badge/info?${ids.map((v) => `ids[]=${v}`).join("&")}`
    );
  }

  getActivityList(): Promise<DtoBadgeActivityList> {
    return this.get<DtoBadgeActivityList>("/badge/activityList");
  }

  getEnabledActivityList(): Promise<DtoEnabledBadgeActivityList> {
    return this.get<DtoEnabledBadgeActivityList>("/badge/enabledActivityList");
  }

  getSellActivityList(): Promise<DtoSellActivityList> {
    return this.get<DtoSellActivityList>("/sell/unexpiredActivities");
  }

  inSellWhiteList(
    activityId: number,
    address: string
  ): Promise<DtoSellInWhiteList> {
    return this.get<DtoSellActivityList>(
      `/sell/inWhiteList/${activityId}/${address}`
    );
  }

  getSellVerifyMessage(
    activityId: number,
    address: string
  ): Promise<DtoGetVerifyMessageRsp> {
    return this.post<DtoGetVerifyMessageRsp>("/sell/getVerifyMessage", {
      activityid: activityId,
      address,
    });
  }

  getSellTransaction(
    activityId: number,
    address: string,
    signature: string
  ): Promise<DtoGetSellTransacitonRsp> {
    return this.post<DtoGetSellTransacitonRsp>("/sell/getSellTransaction", {
      activityid: activityId,
      address,
      signature,
    });
  }

  getHeroInfo(nftId: BigNumber): Promise<unknown> {
    return this.get<unknown>(`/hero/${nftId.toString(10)}`);
  }

  getAlphaInfo(address: string): Promise<any> {
    return this.get<any>(`/faucet-test/info/${address}`);
  }

  getAlphatoSignInfo(address: string): Promise<any> {
    return this.post<any>("/faucet-test/generateSignInfo", { address });
  }

  putAlphaTestInfo(address: string, signature: string) {
    return this.post<any>("/faucet-test/claim", { address, signature });
  }

  getApplyInfo(address: string): Promise<any> {
    return this.get<any>(`/faucet-test/claimResult/${address}`);
  }

  //抽奖活动接口
  getLotteryList() {
    return this.get<any>(`/lottery/getLotteryList`);
  }

  doChainUserLogin(uuid: string, signature: string, owner: string) {
    return this.post<any>(`/login/doChainUserLogin`, {
      uuid,
      signature,
      owner,
    });
  }

  getBoxesInfo() {
    return this.get<any>(`/lottery/getBoxesInfo`);
  }

  getUnClaimRewards() {
    return this.get<any>(`/lottery/getUnClaimRewards`);
  }

  getMerkelProof(boxIds: activityBoxInfo[]) {
    return this.post<any>(`/lottery/getMerkelProof`, {
      boxIds,
    });
  }

  notifyLotteryEvent(activityId: number, eventId: string, type: number) {
    return this.post<any>(`/lottery/notifyLotteryEvent`, {
      activityId,
      eventId,
      type,
    });
  }

  doLottery(activityId: number, eventId: string, type: number) {
    return this.post<any>(`/lottery/doLottery`, {
      activityId,
      eventId,
      type,
    });
  }
  getLotteryActivityList() {
    return this.get<any>(`/lottery/getLotteryActivityList`);
  }

  getReward(eventId: string, type: number, activityId: number) {
    return this.post<any>(`/lottery/getReward`, {
      eventId,
      type,
      activityId,
    });
  }

  verificationCode(email: string, type: string) {
    return this.post<any>(`/login/verificationCode`, { email, type });
  }

  getAccount(address: string, signature: string, uuid: string) {
    return this.post<any>(`/login/getAccount`, { address, signature, uuid });
  }

  resetPasswd(account: string, passwd: string, verificationCode: string) {
    return this.post<any>(`/login/resetPasswd`, {
      account,
      passwd,
      verificationCode,
    });
  }

  // pvp奖励领取获取KT奖励记录接口
  getRewardInfoKT() {
    return this.get<any>(`/pvp-reward/getRewardInfoKT`);
  }

  // pvp奖励领取获取盲盒奖励记录接口
  getRewardInfoBox() {
    return this.get<any>(`/pvp-reward/getRewardInfoMysteryBox`);
  }

  // pvp奖励领取领取奖励接口
  PvPclaimReward(rewardId: number) {
    return this.post<any>(`/pvp-reward/claimReward`, { rewardId });
  }

  // pvp批量领取奖励接口
  PvPclaimRewards(rewardIds: number[]) {
    return this.post<any>(`/pvp-reward/claimRewards`, { rewardIds });
  }
}

export const Api = new ApiClass();
